/* import component */
import DropDown from "../dropdown";

/* import interfaces & types  */
import { Option } from "../dropdown.interfaces";

/* import functions */
import { dropdownDepartment } from '../dropdownhelper';

/* mock data */
import { departments } from "./data";

/* import css */
import '../dropdown.css'

interface Props {
    onChange(option: Option | null): void;
    value: Option | null;
    placeholder?: string
    isDisabled?: boolean;
    label?: string;
    className?: string;
    showLabel?:boolean;
} 

const DropDownDepartment = ({value, onChange, placeholder, className, label, isDisabled, showLabel}: Props) => {    
    /* configure label */  
    showLabel = showLabel === undefined ? true : showLabel
    label = label === undefined ? 'Department' : label

    /* reconfigure data to plug into the general dropdown component */
    let structureData = dropdownDepartment(departments)

    return (

        <div className={`dropdown-employees`}>
            <p className={showLabel===false ? 'd-none' : isDisabled ? 'component-header drop-down-disabled-label' : 'component-header'}>{label}</p>
            <DropDown 
                data={structureData} 
                placeholder={placeholder ?? 'Employee or Department'}  
                onChange={onChange} 
                value={value}
                className={className}
                isDisabled={isDisabled ? isDisabled : false}

            />
        </div>
    );
}

export default DropDownDepartment;