import React, {MouseEvent} from 'react'
import './previousButton.css'

type Props = {
  onClick: () => void,
}

export const PreviousButton = ({onClick}: Props) => (
  <button onClick={(event: MouseEvent<HTMLElement>) => onClick()} className="previous-btn">
    <svg width="9" height="15" viewBox="0 0 9 15" xmlns="http://www.w3.org/2000/svg">
      <path d="M.43 7.664c0 .224.041.43.125.617.083.188.218.373.406.555l5.469 5.351c.265.26.588.391.968.391.256 0 .49-.062.704-.187.213-.125.384-.295.511-.508.128-.214.192-.446.192-.696 0-.39-.151-.736-.453-1.039L3.687 7.656l4.665-4.484c.302-.292.453-.633.453-1.024 0-.255-.064-.488-.192-.699a1.437 1.437 0 0 0-.511-.504 1.367 1.367 0 0 0-.704-.187c-.38 0-.703.13-.968.39L.96 6.492a1.818 1.818 0 0 0-.405.555 1.503 1.503 0 0 0-.125.617z" fill="#000" fillRule="nonzero"/>
    </svg>
  </button>
)