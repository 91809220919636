const convertStr2Num = (time:string) => {
    let timeOfDay:string = time.toLowerCase().includes("p") ? 'PM' : 'AM'
    time = time.toLowerCase().includes("p") ?  time.slice(0, time.indexOf('p')-1) : time.slice(0, time.indexOf('a')-1)
    
    let hours = time.split(":")[0]
    let mins = time.split(":")[1]
    
    hours = timeOfDay === 'PM' ? (parseInt(hours)+ 12).toString() : hours
    return `${hours}:${mins}`
}

const convertNum2Str = (time: string, timeOfDay: string) => {
    let hours = time.split(":")[0]
    let mins = time.split(":")[1]
    mins = isNaN(parseInt(time.split(":")[1])) || mins.length < 1 ? "00" : mins.length === 1? `${mins}0` : time.split(":")[1]

    let timeStr:string = parseInt(hours) > 12 ? `${parseInt(hours)%12}:${mins} ${timeOfDay}` : `${parseInt(hours)}:${mins} ${timeOfDay}`
    timeStr = isNaN(parseInt(hours)) ? '' :  timeStr

    return timeStr
}

const formatStr = (time:string) => {
    let hoursMins:string;
    let displayTime:string;

    if (!(time.includes(":"))) {
        let temp = parseInt(time).toString()
        hoursMins =
            temp.length < 3 ? `${temp.slice(0,2)}:00` 
            : temp.length < 4 ? `${temp.slice(0,1)}:${temp.slice(1)}` 
            : `${temp.slice(0,2)}:${temp.slice(2)}`
    } else {
        hoursMins = time.toLowerCase().includes('p') 
            ? time.slice(0, time.toLowerCase().indexOf('p')) 
            : time.toLowerCase().includes('a') 
            ?  time.slice(0, time.toLowerCase().indexOf('a')) 
            : time.includes(":")
            ? time
            : parseInt(time).toString()
    }

    let timeOfDay:string = parseInt(hoursMins) > 12 || time.toLowerCase().includes("p") ? 'PM' : 'AM'

    if(isNaN(parseInt(hoursMins.split(":").join(""))) === false){
        let a = convertNum2Str(hoursMins, timeOfDay)
        displayTime = a
    } else {
        displayTime = ''
    }
    return displayTime
}

export { convertNum2Str, convertStr2Num, formatStr }