import { Option, GroupedOption } from "../dropdown.interfaces"
/*
 data is already configured so that it can be directly
 plugged into dropdown.tsx
*/

const data:Option[] | GroupedOption[] = [
    {value: "cst", label:"Centeral Standard Time", grouplabel:false},
    {value: "est", label:"Eastern Standard Time", grouplabel:false},
    {value: "pst", label:"Pacific Standard Time", grouplabel:false},
    {value: "ast", label:"Alaskan Standard Time", grouplabel:false},
    {value: "hst", label:"Hawaii-Aleutian Standard Time", grouplabel:false},
]

export default {data}