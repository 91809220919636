import DatePicker from 'react-datepicker'

import { SingleValueProps } from '../datepicker';

import "react-datepicker/dist/react-datepicker.css"
import '../datepicker.css';
import { useContext } from 'react';
import DropsContext, { IDropsContext } from '../../../models/Context';

const DatePickerContainer = (props:SingleValueProps) => {
  const context = useContext(DropsContext)
  const {dropsState: {selectedTimeEntry}} = context
  let isDisabled = props['disabled']
  const displayDate = new Date(selectedTimeEntry.Date)
  function convertDateToUTC(date: Date) { 
    return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds()); 
    }
   
      return (
        <div className='datepicker'>
          <DatePicker 
            onChange={props['onChange']}
            selected={convertDateToUTC(new Date(selectedTimeEntry.Date))}
            disabled={isDisabled}
            className={isDisabled ? `datepicker-single ${props['className']} disabled` : `datepicker-single ${props['className']}`}
          />
        </div>
      )
}

export default DatePickerContainer;
