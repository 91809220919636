/* import interfaces & types */
import type { IncomingData, Option, GroupedOption, DataGroup, DataGroupOption, Department, ProjectDataType, Users } from "./dropdown.interfaces";

/* helper data */
const data:GroupedOption[] = 
[
  {
      "label": "3Pillar",
      "options": [
          {
              "value": "14721249",
              "label": "3Pill-001: ClassEDU",
              "grouplabel": false
          }
      ]
  },
  {
      "label": "Alive Hospice",
      "options": [
          {
              "value": "14220752",
              "label": "Alive-002",
              "grouplabel": false
          }
      ]
  },
  {
      "label": "BMI",
      "options": [
          {
              "value": "14916633",
              "label": "BMI-013: App Updates",
              "grouplabel": false
          }
      ]
  }
]

const placeholder = 'Project';
const placeholder2 = 'Employee';

const grouped = false;
const grouped2 = true;

/* ------functions------ */
/* 
  helper function for groupOptions
  iterates through group label's data and reconfigures it for the dropdown menu
  return the reconfigured data
*/
const createGroup = ({groupopt, data}: DataGroupOption) => {
  const arr: Option[] = data[groupopt].map((option: IncomingData) => {
      const optiondict = { 
        value: option['id'], 
        label: option['name'], 
        grouplabel: false }
      return optiondict
  })
  
  return arr
};

/*
  creates labels and option data for the dropdown menu
  this function is for grouped labels
*/
const groupOptions = ({dataArr, data}:DataGroup) => {
  const arr: GroupedOption[] = dataArr.map((groupopt: string) => {
      const groupoption = createGroup({groupopt, data});
      const group = { label: groupopt, options: groupoption };
      return group
  })
  return arr;
}

/*
  creates labels and option data for the dropdown menu
  this function is for ungrouped labels
*/
const createOptions = ({dataArr, data}:DataGroup) =>{
  const arr: Option[] = [];

  const options = dataArr.reduce((arr, item) => {
    return arr.concat([
      { value: item.toUpperCase(), label: item.toUpperCase(), grouplabel:true, archive:false},
      ...data[item].map(itm => {
        let isArchive = itm['archive'] === 'true' ? true: false;
        return (
          { 
            value: itm['id'],
            label: itm['name'],
            grouplabel:false, 
            archive: isArchive,
          }
          )
        })
    ])
  }, arr)

  return options
};

/* for formating data directly into react-select options */
const dropdownDepartment = (departments:Array<Department>) => {
  const arr: Option[] = [];

  const options = departments.reduce((arr, item) => {
      return arr.concat([
          { value: item['id'].toString(), label: item['name'], grouplabel:true, archive:false},
      ])
  }, arr)

  return options
}

const dropdownClient = (data: ProjectDataType) => {
  const projects = data['data'];
  const arr: Option[] = [];
  const clients:Array<string> = []

  for (let item of projects) {
      if (clients.includes(item['bcClientName'])===false) {
          arr.push({ value: item['bcClientID'].toString(), label: item['bcClientName'], grouplabel:true, archive:false})
          clients.push(item['bcClientName'])
      }
  }

  return arr
}


const dropdownEmployee = (employees: Users[]) => {
  const arr: Option[] = [];

  const options = employees.reduce((arr, item) => {
      return arr.concat([
          { value: item['bcUserID'].toString(), label: `${item['bcFirstName']} ${item['bcLastName']}`, grouplabel:true, archive:false},
      ])
  }, arr)

  return options
}


export { data, placeholder, placeholder2, grouped, grouped2, groupOptions, createOptions, dropdownClient, dropdownDepartment, dropdownEmployee, Option }

