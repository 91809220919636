import React, { useContext, useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import DropsContext from "../../models/Context";
import { TimeEntries, TimeEntry } from "../../models/TimeEntry";
import "./reportTimeEntries.css";
import { sortTimeEntryBy, sortTimeEntryByUser } from "./utility";

function ReportTimeEntry() {
  const context = useContext(DropsContext);
  const {
    dropsState: { timeEntries },
  } = context;
  let totalHours = 0;
  const [sortProperty, setSortProperty] = useState<keyof TimeEntry>("Date");
  const [descending, setDescending] = useState<boolean>(false);

  const handleSort = (sortBy: keyof TimeEntry) => {
    // if (sortProperty === sortBy) {
    //   setDescending(!descending);
    // } else {
    //   setSortProperty(sortBy);
    //   setDescending(false);
    // }
    // if (sortBy == "User") {
    //   setEntries(sortTimeEntryByUser(context));
    // } else {
    //   setEntries(sortTimeEntryBy(sortProperty, context, descending));
    // }
  };

  const handleCSVExport = () => {};

  const getSortIcon = (colName: keyof TimeEntry) => {
    return (
      <i
        className={`bi bi-caret-down-fill sort-arrow${
          colName == sortProperty
            ? `--selected${descending ? "--descending" : ""}`
            : ""
        }`}
      ></i>
    );
  };

  return (
    <div className="report-table-wrapper">
      {timeEntries.length > 0 && (
        <>
          <div className="report-subheading">
            <Button onClick={handleCSVExport} id={"csv-export-btn"}>
              EXPORT CSV
            </Button>
            <p className="requirements-text">* UNCONFIRMED TIME</p>
          </div>
          <div className="report-table">
            <table className="table">
              <thead className="report-table-header">
                <tr>
                <th
                  scope="column"
                  className="text-start date-header clickable-header"
                  onClick={() => handleSort("Date")}
                >
                  {getSortIcon("Date")}Date
                </th>
                <th
                  scope="column"
                  className="text-start clickable-header"
                  onClick={() => handleSort("Description")}
                >
                  {getSortIcon("Description")}Description
                </th>
                <th
                  scope="column"
                  className="text-start clickable-header"
                  onClick={() => handleSort("User")}
                >
                  {getSortIcon("User")}Employee
                </th>
                <th
                  scope="column"
                  className="clickable-header"
                  onClick={() => handleSort("Hours")}
                >
                  {getSortIcon("Hours")}Time
                </th>
                </tr>
              </thead>
              <tbody>
                {timeEntries.map((entry) => {
                  totalHours += entry.Hours;
                  return (
                    <tr className={`report-row${entry.Confirmation?.Confirmed ? '' : '--unconfirmed'}`} key={`timeEntry--${entry.Id}`}>
                      <td scope="row" className="text-start">
                        {entry.Date}
                      </td>
                      <td className="text-start table-cell-description">
                        {entry.Description}
                      </td>
                      <td className="text-start">
                        {entry.User?.FirstName + " " + entry.User?.LastName}
                      </td>
                      <td>{entry.Hours.toPrecision(3)}</td>
                    </tr>
                  );
                })}
                <tr className="table-footer">
                  <td className="text-start">
                    <Button onClick={handleCSVExport} id={"csv-export-btn"}>
                      EXPORT CSV
                    </Button>
                  </td>
                  <td></td>
                  <td></td>
                  <td className="total-hours">{totalHours.toPrecision(3)}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </>
      )}
    </div>
  );
}

export default ReportTimeEntry;
