import { useReducer } from "react";
import DropsContext, {
  initialState,
  IDropsContextProvider,
} from "../models/Context";
import { DropsReducer } from "./DropsReducer";

const DropsProvider = ({ children }: IDropsContextProvider) => {
  const [dropsState, dispatch] = useReducer(DropsReducer, initialState);

  return (
    <DropsContext.Provider value={{ dropsState, dispatch }}>
      {children}
    </DropsContext.Provider>
  );
};

export { DropsProvider };
