import context from "react-bootstrap/esm/AccordionContext";
import {
  deleteTimeEntryForUser,
  getTimeEntriesForUser,
  postTimeEntryForUser,
  putTimeEntryForUser,
} from "../../api/TimeEntry";
import { IDropsContext } from "../../models/Context";
import { TimeEntry } from "../../models/TimeEntry";
import { DropsActionType } from "../Actions";

export const submitUserTime = (context: IDropsContext) => {
  postTimeEntryForUser(context);
};

export const updateUserTime = (context: IDropsContext) => {
  putTimeEntryForUser(context);
};

export const deleteUserTime = (context: IDropsContext) => {
  deleteTimeEntryForUser(context);
};

export const getUserTime = (context: IDropsContext) => {
  getTimeEntriesForUser(context);
};

export const clearTimeEntries = (context: IDropsContext) => {
  context.dispatch({
    type: DropsActionType.CLEAR_TIME_ENTRIES,
  });
};

export const setSelectedTimeEntry = (
  data: TimeEntry,
  context: IDropsContext
) => {
  context.dispatch({
    type: DropsActionType.SET_SELECTED_TIME_ENTRY,
    payload: data,
  });
};
export const clearSelectedTimeEntry = (context: IDropsContext) => {
  context.dispatch({ type: DropsActionType.CLEAR_SELECTED_TIME_ENTRY });
};
