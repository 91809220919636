export type User = {
    Id: number,
    FirstName: string,
    LastName: string,
    GoogleUserId: string,
    Email: string,
    AvatarUrl:string,
    Deleted: boolean,
    CreateAt: Date,
    TargetBillablehours?: number,
    TargetHours?: number,
    DepartmentId?: number,
    RowKey?: string,
    DepartmentRowKey?: string,
    SlackHandle?: string,
    Confirmations: boolean,
    TimeZone: string,
    Admin: boolean,
}

export const initialUser: User = {
    Id: 0,
    FirstName: '',
    LastName: '',
    GoogleUserId: '',
    Email: '',
    AvatarUrl:'',
    Deleted: true,
    CreateAt: new Date(),
    Confirmations: true,
    TimeZone: '',
    Admin: false,
}