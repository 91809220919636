import DatePicker from 'react-datepicker';
import { DateRangeProps } from '../datepicker';

import "react-datepicker/dist/react-datepicker.css"
import '../datepicker.css';

const MultiDatePickerContainer = (props:DateRangeProps) => {
  let isDisabled = props['disabled']

    return (
      <div className='datepicker'>
        <DatePicker 
          onChange={props['onChange']}
          startDate={props['startDate']}
          endDate={props['endDate']}
          disabled={isDisabled}
          selectsRange
          className={isDisabled ? `${props['className']} disabled` : `${props['className']}`}
        />
      </div>
    );
}

export default MultiDatePickerContainer;
