import type { Department, Users, IncomingData } from "../../../components/DropDown/dropdown.interfaces";

const findDepartment = (departmentID: string, departments: Array<Department>) => {
    return departments.find(d => d.id.toString() === departmentID)?.name || ""
}

const organizeEmployees = (departments: Array<Department>, users: Array<Users>) => {
    let structureData: IncomingData[] = []

    let userKeys = Object.keys(users)
    for (let key in userKeys) {
        let name = `${users[key]['bcFirstName']} ${users[key]['bcLastName']}`
        let departmentID = users[key]['departmentId'].toString()
        let departmentName = findDepartment(departmentID, departments)
        let obj = {
            'name': name,
            'userID': users[key]['bcUserID'].toString(),
            'departmentID': departmentID,
            'departmentName': departmentName,
            'timezone': users[key]['timeZone'].toString(),
            'isAdmin': users[key]['admin'].toString(),
            'email': users[key]['bcEmail'].toString(),
            'startDate': users[key]['bcCreatedAt'],
            'archive':users[key]['bcDeleted'].toString(),
        }
        structureData.push(obj)
    }

    return structureData
}

export { organizeEmployees }