import { useState } from 'react';
import './textfield.css';

interface Props {
    fieldText: string;
    isValid: boolean;
    label: string;
    placeholder?: string;
    errormsg?: string;
    isDisabled: boolean;
    className?: string;
    showLabel?: boolean;
    onChange(e:React.ChangeEvent<HTMLInputElement>): void;
}

const TextField = ({fieldText, placeholder, isValid, label, errormsg, isDisabled, className, onChange, showLabel}:Props) => {
  //const [ text, setText ] = useState<string>(fieldText)
  isValid ? label=label : label="error";
  let showlabel = showLabel === false ? 'd-none' : ''
  let valid = isValid ? "" : "invalidText"
  let disabled = isDisabled ? "textfield-header-disabled" : ""
  let classes = `${showlabel} ${valid} ${disabled}`

  return (
    <div className={`textfield`}>
        <label 
          htmlFor="input" 
          className={classes}
        >
          {label.toUpperCase()}
        </label>
        <input 
          type="text"
          value={fieldText}
          placeholder={placeholder}
          id='input' 
          disabled={isDisabled}
          className={
            isDisabled ? `disable ${className}` : ""  
            + isValid? `default ${className}` : 
            `invalid ${className}`
          }
          onChange={onChange} 
          //onBlur={onChange}
        />
        <p className={isValid ? "d-none" : "fc-feedback"}>{errormsg}</p>
    </div>
  );
}

export default TextField;
