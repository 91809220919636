import React, { useState, useEffect } from 'react';

/* components */
import LogoutButton from '../../components/LogoutButton/logout';
import DropDownTimeZone from '../../components/DropDown/TimeZone/dropdownTimeZone';
import TimeInputMain from '../../components/TimeTextField/timeinputmain';
import Mobile from '../../components/MobileTextField/mobile';
import DropDownDay from '../../components/DropDown/Days/day';
import DropDownNotification from '../../components/DropDown/Notification/notification';
import DropDownAppearence from '../../components/DropDown/Appearence/appearence';
import { Button } from 'react-bootstrap';

/* interfaces */
import { Option } from '../../components/DropDown/dropdown.interfaces';
import { Notification } from './settings.interfaces';

/* functions */
import { normalizeMobileNumber } from '../../components/MobileTextField/utils'
import { formatStr } from '../../components/TimeTextField/utils';
import { mapAppearenceToSelect } from '../../components/DropDown/Appearence/utils'
import { mapTimeZoneToSelect } from '../../components/DropDown/TimeZone/utils';
import { updateNotifications } from './utils'

/* style sheets */
import './settings.css';


const Settings = () => {
  const [ timeZone, setTimeZone ] = useState<Option|null>(null)
  const [ appearence, setAppearence ] = useState<Option|null>(null)
  const [ phoneNumber, setPhoneNumber ] = useState<string>('')
  const [ userNotifs, setUserNotifs ] = useState<Notification[]>([])
  const [ enableMobile, setEnableMobile ] = useState<boolean>(false);

  const onChangeTimeZone = (value:Option) => {
    setTimeZone(value)
  }

  const onChangeAppearence = (value:Option) => {
    setAppearence(value)
  }

  const onChangeNotification = (value:Option | null, notif:Notification) => {
    if (value != null) {
      let strValue = value.value
      const tempNotif = updateNotifications({
        userNotifs, 
        notif, 
        value: strValue, 
        type: 'type'
      })
      
      setUserNotifs(tempNotif)
    }
  }

  const onChangeDay = (value:Option | null, notif:Notification) => {
    if (value != null) {
      let strValue = value.value
      const tempNotif = updateNotifications({
        userNotifs, 
        notif, 
        value: strValue, 
        type: 'day'
      })
      
      setUserNotifs(tempNotif)
    }
  }

  const onChangeTime = (displayTime:React.ChangeEvent<HTMLInputElement> | string, notif:Notification) => {
    let temp = typeof displayTime === 'string' ? displayTime : displayTime.target.value

    const tempNotif = updateNotifications({
      userNotifs, 
      notif, 
      value: temp, 
      type: 'time'
    })

    setUserNotifs(tempNotif)
  }

  const onBlurMobile = (mobileNumber:string, error:boolean) => {
    if (error) {
      setEnableMobile(false)
    } else {
      setPhoneNumber(mobileNumber)
      setEnableMobile(true)
    }
  }

  const onClickDeleteNotif = (notif:Notification) => {
    let tempNotif: Notification[] = []

    for (let x of userNotifs) {
      if (x != notif) {
        tempNotif.push(x)
      }
    }

    setUserNotifs(tempNotif)
  }

  useEffect(() => {
    // temp user data
    let userMobile = "1234567890"
    let appearence = 'light'
    let userTimeZone = 'pst'

    let userNotifications = [
      {
          type: 'slack',
          time: '13:00',
          day: 'same-day',
        },
      {
          type: 'email',
          time: '6:00',
          day: 'next-day',
        },
    ]

    for (let notif of userNotifications) {
      notif['time'] = formatStr(notif['time'])
    }

    // set all variables
    setUserNotifs(userNotifications)
    setAppearence(mapAppearenceToSelect(appearence))
    setTimeZone(mapTimeZoneToSelect(userTimeZone))

    if (userMobile.length > 1) {
      setPhoneNumber(normalizeMobileNumber(userMobile))
      setEnableMobile(true)
    } else {
      setPhoneNumber("")
    }
  }, [])
  
  const notification = userNotifs.map((notif:Notification, index) => {
    const typeSelect: Option = { 
      value: notif.type, 
      label: notif.type.charAt(0).toUpperCase() + notif.type.slice(1),
      grouplabel: false }
    const daySelect: Option = { 
      value: notif.day, 
      label: notif.day.replace('-', ' ').split(' ')
      .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
      .join(' '), 
      grouplabel: false 
    }

   return (
    <div 
      className='notifications d-flex flex-row align-items-baseline justify-content-between'
    >
      <div className={index > 1 ? '' : 'd-none'}>
        <img 
          src="./delete.png" 
          alt="delete" 
          className='delete-png' 
          onClick={()=>onClickDeleteNotif(notif)}
        />
      </div>
      <div>
        <h3 className={index === 0 ? 'component-header' : 'd-none'}>Format</h3>
        <DropDownNotification 
          onChange={(e) => onChangeNotification(e, notif)}
          value={typeSelect}
          className={index > 1 ? 'dropdown-format-sm' : 'dropdown-format'}
          showLabel={false}
          mobileEnabled={enableMobile}
        />
      </div>
      <div>
        <h3 className={index === 0 ? 'component-header' : 'd-none'}>Time</h3>
        <TimeInputMain 
          onBlur={(e) => onChangeTime(e, notif)}
          time={notif.time}
          showError={true}
        />
      </div>
      <div>
        <h3 className={index === 0 ? 'component-header' : 'd-none'}>Day</h3>
        <DropDownDay 
          onChange={(e) => onChangeDay(e, notif)}
          value={daySelect}
          className={'dropdown-day'}
          showLabel={false}
        />
      </div>
    </div>
   )
  })

  const addNotification = () => {
    let temp = userNotifs.slice()
    temp.push(
      {
        type: 'slack',
        time: '10:00',
        day: 'next-day',
      },
    )
    
    setUserNotifs(temp)
  }

  return (
    <div className="settings App">
      <h1 className='page-header'>Account Settings</h1>

      <div className='logout-button d-flex flex-align-start'>
        <LogoutButton />
      </div>

      <div className='d-flex flex-row justify-content-between'>
        <div>
          <DropDownAppearence
            onChange={onChangeAppearence}
            value={appearence}
            className={'dropdown-appearence'}
          />
        </div>
        <div>
          <DropDownTimeZone
            onChange={onChangeTimeZone}
            value={timeZone}
            className={'dropdown-time-zone'}
          />
        </div>
      </div>
      <div className='phone-number' role={'mobile-number'}>
        <Mobile
          mobileNumber={phoneNumber}
          onBlur={onBlurMobile}
          isDisabled={false}
        />
      </div>
      <div>
        <h1 className='section-header'>Notifications</h1>
        <div>
          {notification}
        </div>

        <Button
          id={'add-notification-btn'}
          onClick={addNotification}
          className={'flex d-flex align-items-start'}
        >
          + ADD NOTIFICATION
        </Button>
      </div>
    </div>
  );
}

export default Settings;
