import { IDropsContext } from "../models/Context";

export const getEmployees = (context: IDropsContext) => {

  return fetch(process.env.REACT_APP_BASE_URL + "/v1/users", {
    method: "Get",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error(response.statusText);
      } else {
        return response.json();
      }
    })
  };