/* import component */
import DropDown from "../dropdown";

/* import interfaces & types  */
import { Option } from "../dropdown.interfaces";

/* functions */
import { createOptions } from '../dropdownhelper';

/* mock data */
import original from "./data";

interface Props {
    onChange(option: Option | null): void;
    value: Option | null;
    placeholder?: string;
    isDisabled?: boolean;
    label?: string;
    className?: string;
    showLabel?:boolean;
} 


const DropDownEmployeeType = ({value, onChange, placeholder, className, label, isDisabled, showLabel}: Props) => {    
    /* configure label */  
    showLabel = showLabel === undefined ? true : showLabel
    label = label === undefined ? 'Employee Type' : label

    const options = original['data'];

    return (
        <div className="dropdown-employees">
            <p className={showLabel===false ? 'd-none' : isDisabled ? 'component-header drop-down-disabled-label' : 'component-header'}>{label}</p>
            <DropDown 
                data={options} 
                placeholder={placeholder ?? 'Employee Type'}
                onChange={onChange} 
                value={value} 
                className={className}
                isDisabled={isDisabled ? isDisabled : false}
            />
        </div>
    )
}

export default DropDownEmployeeType;