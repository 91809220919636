import { DropsActionType } from "../context/Actions";
import { IDropsContext } from "../models/Context";
import { NewProject, Project } from "../models/Projects";
import { getClients, getExpandedClients } from "./Clients";

export const createProject = async (
  project: NewProject,
  context: IDropsContext
) => {
  return await fetch(process.env.REACT_APP_BASE_URL + "/v1/projects", {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(project),
  })
    .then(() => {
      getExpandedClients(context);
    });
};

export const updateProject = async (
  project: Project,
  context: IDropsContext
) => {
  return fetch(process.env.REACT_APP_BASE_URL + `/v1/projects/${project.Id}`, {
    method: "PUT",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(project),
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error(response.statusText);
      } else {
        getExpandedClients(context)
      }
    }).catch((err)=>{console.log("Project update failed", err)})
};

export const getProjects = async (context: IDropsContext) => {
  const { dispatch } = context;
  return await fetch(process.env.REACT_APP_BASE_URL + "/v1/projects", {
    method: "GET",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error(response.statusText);
      } else {
        return response.json();
      }
    })
    .then((projects) => {
      dispatch({
        type: DropsActionType.GET_PROJECTS,
        payload: projects,
      });
    });
};
export const deleteProjects = async (Id: number, context: IDropsContext) => {
  return await fetch(process.env.REACT_APP_BASE_URL + "/v1/projects/:id", {
    method: "DELETE",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error(response.statusText);
      } else {
        getProjects(context);
      }
    })
};
