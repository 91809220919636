import React, { useState, useEffect } from 'react';

import './employees.css';
import '../../../App.css';

import { Modal, Button } from 'react-bootstrap';

import AddEmployee from '../../../components/AddEmployee/addEmployee';

import { EmployeeDataProps } from '../../../components/AddEmployee/AddEmployees.interfaces';
import { IncomingData, Option } from '../../../components/DropDown/dropdown.interfaces';

import { organizeEmployees } from './function';

import { users, departments } from './helper'

const Employees = () => {
  const [ data, setData ] = useState<IncomingData[] | undefined>(); // data 
  const [ originalData, setOriginalData ]= useState<IncomingData[] | undefined>(); // copy of data to keep for comparisons
  const [ archivedOnly, setArchiveOnly ] = useState<boolean>(false);

  const [ searchBarActive, setSearchBarActive ] = useState<boolean>(false); // boolean indicating if search bar is active
  const [ colOneActive, setColOneActive ] = useState<boolean>(true); // boolean indicating if employee column is active
  const [ colTwoActive, setColTwoActive ] = useState<boolean>(false); // boolean indicating if department column is active
  const [ isOpen, setIsOpen ] = useState<boolean>(false); // boolean indicating if popup is open
  const [ editEmployee, setEditEmployee ] = useState<boolean>(false); // boolean for popup, indicates if this is a current employee being edited
  const [ employee, setEmployee ] = useState<Partial<EmployeeDataProps>>({ })
  const [ isHidden, setIsHidden ] = useState<boolean>(false);

  useEffect(() => {
      let archivedemployeesbydept = organizeEmployees(departments, users)
      
      setData(archivedemployeesbydept)
      setOriginalData(archivedemployeesbydept)
    }, [])

  if (colOneActive && data != undefined) { // sort table via employee column
      data.sort((a:IncomingData, b:IncomingData) => {
          if(a.name < b.name) { return -1; }
          if(a.name > b.name) { return 1; }
          return 0;
      })
  } else if (colTwoActive && data != undefined) { // sort table via department column
      data.sort((a:IncomingData, b:IncomingData) => {
          if(a.departmentName < b.departmentName) { return -1; }
          if(a.departmentName > b.departmentName) { return 1; }
          return 0;
      })
  } 

  // event listener function for each employee, gets data and gives it to pop up
  const getEmployee = (employee:IncomingData) => { 
      setIsOpen(true)
      const departmentSelect: Option = {'label': employee.departmentName, 'value': employee.departmentID, grouplabel: false}
      const timezoneSelect: Option = {'label': employee.timezone, 'value': employee.timezone, grouplabel: false}
      const employeeTypeSelect: Option = {value: "full-time", label:"Full Time", grouplabel:false}
      const isAdmin = employee.isAdmin === 'true' ? true : false
      const archive = employee.archive === 'true' ? true : false

      setEmployee({
              department: departmentSelect,
              timezone: timezoneSelect,
              employeeType:employeeTypeSelect,
              employeeName:employee.name,
              email:employee.email,
              targetHours:null,
              targetBillableHours:null,
              vacationTime:null,
              startDate: new Date(employee.startDate),
              isAdmin: isAdmin,
              isDepartmentHead:false,
              canRunReport:false,
              confirmationDays:['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'],
              archived: archive,
      })

      setEditEmployee(true)
  }


  // organizes employees into table rows
  const alldepartments = data?.map((employee:IncomingData) => {
      let archive = employee.archive === 'true' ? true : false 
      if (archive === archivedOnly) {
        return (
          <tr 
              id={employee.userID}
              key={employee.userID}
              onClick={()=>getEmployee(employee)}
          >
              <td 
                className='padding-left'
              >{employee.name}</td>
              <td 
                className='padding-left'
              >{employee.departmentName}</td>
          </tr>
        )
      } 
  })

  const getSearch = (event: React.ChangeEvent<HTMLInputElement>) => { // event listener for search queries
    let searchQ = event.target.value
    let newArchive = []
    
    if (searchQ != '' && originalData != undefined) {
        for (let employee of originalData) {   
            const { name } = employee
            const { departmentName } = employee

            if (name.toLowerCase().includes(searchQ.toLowerCase())===true || departmentName.toLowerCase().includes(searchQ.toLowerCase())===true) {
                newArchive.push(employee)
            }
        }
        setData(newArchive)
    } else {
        setData(originalData)
    }
    
  }

  const handleShow = () => { // opens popup
    setIsOpen(true)
  }

  const handleClose = () => { // closes popup and sets data to default
    setIsHidden(false)
    setEditEmployee(false)
    setIsOpen(false)
    setEmployee({
      department:null,
      timezone:null,
      employeeType:null,
      employeeName:null,
      email:null,
      targetHours:null,
      targetBillableHours:null,
      vacationTime:null,
      startDate:null,
      isAdmin:false,
      isDepartmentHead:false,
      canRunReport:false,
      confirmationDays:[],
      archived: false
    })
  }

  const activateColOne = () => { // activates col employee
      setColOneActive(!colOneActive)
      setColTwoActive(false)
      setData(originalData)
  }

  const activateColTwo = () => { // activates col department
      setColTwoActive(!colTwoActive)
      setColOneActive(false)
      setData(originalData)
  }

  const toArchivedEmployee = () => { // sets archiveOnly & editEmployee to true
    setArchiveOnly(true)
    setEditEmployee(true)
  }

  const toEmployees = () => { // sets archiveOnly & editEmployee to false
    setArchiveOnly(false)
    setEditEmployee(false)

  }
  
  const handleTempHide = (hidden:boolean) => { // modulates isHidden which hides/shows the confirmation popup in addEmployee
    setIsHidden(hidden)
  }

  return (
    <div className="employees d-flex align-items-center flex-column">
      <div className={archivedOnly ? 'd-none' : ''}>
        <h1 className='page-header'>Employees</h1>
      </div>
      <div className={archivedOnly ? '' : 'd-none'}>
        <h1 className='page-header'>Former Employees</h1>
      </div>

      <div 
        className='button-container d-flex justify-content-between'
      >
        <div className={archivedOnly ? 'd-none' : ''}>
          <Button
            onClick={handleShow}
            id={"addEmployee-btn"}
          >
            + ADD EMPLOYEE
          </Button>
        </div>

        <div className={archivedOnly ? 'd-none' : ''}>
          <Button
            id={"formerEmployees"}
            onClick={toArchivedEmployee}
          >
            FORMER EMPLOYEES
          </Button>
        </div>

        <div className={archivedOnly ? '' : 'd-none'}>
          <Button
            id={"formerEmployees"}
            onClick={toEmployees}
          >
            Back
          </Button>
        </div>
      </div>

      <div 
        className={searchBarActive === true ? 'active searchbar-container d-flex align-items-center': 'searchbar-container d-flex align-items-center'}
      >
        <i className='bi bi-search' />
        <input
            type="search"
            name="search-project-archive"
            id="search-project-archive"
            placeholder='Search Employees'
            onChange={getSearch}
            className={'input-searchbar'}
            onFocus={()=>setSearchBarActive(true)}
            onBlur={()=>setSearchBarActive(false)}

        />
      </div>

      <div className='archived-employees-container'>
        <table id='archived-employees-table'>
          <tbody>
            <tr id="employees-table-header">
                <th
                    onClick={activateColOne}
                    className={"table-headers padding-left"}
                >
                    Employee
                    <img src={colOneActive === true ? "/sort-light-down-active.svg" : "/sort-light-down-inactive.svg"} alt="" />
                </th>
                <th
                    onClick={activateColTwo}
                    className={"table-headers padding-left"}
                >
                    Department
                    <img src={colTwoActive === true ? "/sort-light-down-active.svg" : "/sort-light-down-inactive.svg"} alt="" />
                </th>
            </tr>
            {alldepartments}
          </tbody>
        </table>
      </div>

      <Modal show={isOpen} onHide={handleClose} className={ isHidden ? 'd-none':'employee-popup' }>
        <Modal.Body id={'modal-width-lg'}>
          <AddEmployee archived={archivedOnly} data={employee} handleClose={handleClose} edit={editEmployee} handleHide={handleTempHide} />
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default Employees;
