import { getEmployees } from "../../api/Users";
import { IDropsContext } from "../../models/Context";
import { DropsActionType } from "../Actions";

export const getAllEmployees = (context: IDropsContext) => {
    const { dispatch } = context;
    //user should be admin
    getEmployees(context).then((employees) => {
        dispatch({
          type: DropsActionType.SET_EMPLOYEES,
          payload: employees,
        });
      });
}