import { ClientSummary } from "./clientSummary";
import { Switch } from "./switch";
import "./timeEntrySummary.css";
import { ChangeEvent, useContext, useEffect } from "react";
import DropsContext from "../../models/Context";
import {
  ProjectTimeEntry,
  TimeEntries,
} from "../../models/TimeEntry";
import { getProjects } from "../../api/Projects";
import { setConfirmation } from "../../api/Confirmation";


export const TimeEntrySummary = () => {
  const context = useContext(DropsContext);
  const {
    dropsState: { timeEntries, projects },
  } = context;
  let totalHours: number = 0;
  useEffect(() => {
    if (projects.length < 1) {
      getProjects(context);
    }
  }, []);

  const handleConfirmationChange = (e: ChangeEvent) => {
    e.preventDefault();
    setConfirmation(timeEntries[0].ConfirmationId, context)
  }

  const timeEntryToSummary = (timeEntries: TimeEntries) => {
    const projectIdList: number[] = [];
    const projectEntries: ProjectTimeEntry[] = [];
    timeEntries.forEach((timeEntry) => {
      if (projectIdList.includes(timeEntry.ProjectId)) {
        const i = projectEntries.findIndex((projectEntry) => {
          return projectEntry.Id === timeEntry.ProjectId;
        });
        projectEntries[i].timeEntries.push(timeEntry);
      } else {
        projectIdList.push(timeEntry.ProjectId);
        const i = projects.findIndex((project) => {
          return project.Id === timeEntry.ProjectId;
        });
        projectEntries.push({ ...projects[i], timeEntries: [timeEntry] });
      }
      totalHours += timeEntry.Hours;
    });
    return projectEntries;
  };
  const timeEntrySummaries =
    timeEntries && projects.length > 0 ? timeEntryToSummary(timeEntries) : [];
  return (
    <div className="summary-ctn">
      {timeEntrySummaries.map((summary, key) => (
        <ClientSummary key={key} summary={summary} />
      ))}
      {timeEntries.length > 0 && (
        <div className="summary-total">
          <span>{totalHours}</span>
          <Switch active={timeEntries[0]?.Confirmation ? timeEntries[0].Confirmation.Confirmed : false} onChange={handleConfirmationChange} />
        </div>
      )}
    </div>
  );
};
