/* import component */
import DropDown from "../dropdown";

/* import types and interfaces */
import { Option } from "../dropdown.interfaces";

/* import mock data */
import original from "./data";


interface Props {
    onChange(option: Option | null): void;
    value: Option | null;
    placeholder?:string;
    isDisabled?: boolean;
    className?: string;
    label?: string;
    showLabel?:boolean;
}

const DropDownTime = ({value, onChange, placeholder, className, label, showLabel, isDisabled}: Props) => {  
    /* configure label */  
    showLabel = showLabel === undefined ? true : showLabel
    label = label === undefined ? 'Time Frame' : label
  

    /* get data from helper */
    const options = original['data']

    return (
        <div className="dropdown-projects">
            <p className={showLabel===false ? 'd-none' : isDisabled ? 'component-header drop-down-disabled-label' : 'component-header'}>{label}</p>
            <DropDown 
                data={options} 
                placeholder={placeholder ?? 'Time Frame'} 
                onChange={onChange} 
                value={value} 
                className={className}
                isDisabled={isDisabled ? isDisabled : false}
            />
        </div>
    );
}

export default DropDownTime;