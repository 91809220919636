import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import LoginButton from "../../components/LoginButton/loginbutton";
import { gapi } from 'gapi-script';
import "./login.css"

let clientVar:string | undefined = process.env.REACT_APP_GOOGLE_CLIENT_ID;
let clientID = typeof clientVar === 'string' ? clientVar : ""

const Login = () => {
    const navigate = useNavigate()

    const getIsLoggedIn = () => {
        navigate(`/time`); 
    }

    useEffect(() => {
        const start = () =>{
            gapi.client.init({
                clientId: clientID,
                scope: "email profile",
            })
        };
        if(!gapi){
            gapi.load('client:auth2', start);
        }
    },[])

  return (
    <div className="loginpg App d-flex flex-column">
        <div className="d-flex flex-column">
            <div className="d-flex align-items-start">
                <img src="/fill-1.png" alt="drops logo" />
                <p className="drops-logo">Drops</p>
            </div>
            <p>Welcome Back, Login</p>
        </div>
        <LoginButton 
            isLoggedIn={getIsLoggedIn}
        />
    </div>
  );
}

export default Login;
