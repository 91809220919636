import "./checkbox.css"

interface Props {
  labeltext: String;
  onClick(checked: boolean):void;
  isClicked:boolean;
  isDisabled:boolean;
}

const CheckBox = ({labeltext, onClick, isClicked, isDisabled}:Props) => {

  if (isDisabled) {
    return (
      <div className={`checkbox-container-inactive d-flex form-row align-items-baseline justify-content-center`}>
          <span className='checkbox-container-disabled'>
              <input type="checkbox" checked={isClicked} className="checkbox" id="checkbox" readOnly/>
              <span className='checkbox-inactive'>{'\u2713'}</span>
          </span>
          <p className='disabled-label'>{labeltext.toLocaleUpperCase()}</p>
      </div>
    );
  } else {
    return (
      <div className="checkbox-container-active d-flex form-row align-items-baseline justify-content-center">
          <span onClick={ (e) => onClick(!isClicked)}>
              <input type="checkbox" checked={isClicked} className="checkbox" id="checkbox" readOnly/>
              <span className='checkbox-active'>{'\u2713'}</span>
          </span>
          <p>{labeltext.toLocaleUpperCase()}</p>
      </div>
    );
  }
}

export default CheckBox;
