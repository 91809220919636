import { createClientAndProject, getExpandedClients } from "../../api/Clients";
import { updateProject } from "../../api/Projects";
import { Client } from "../../models/Client";
import { IDropsContext } from "../../models/Context";
import { getClientByName } from "../../pages/Admin/Projects/utils/project";

export const createClientAndUpdatProject = async (
  clientName: string,
  context: IDropsContext
) => {
  const clientId = await createClientAndProject({ Name: clientName }, context);
  if (clientId) {
    await updateProject(
      { ...context.dropsState.selectedProject, ClientId: parseInt(clientId) },
      context
    );
  }
  await getExpandedClients(context);
};
