import { Client } from "../../models/Client";
import { IDropsContext } from "../../models/Context";
import { DropsActionType } from "../Actions";

export const setSelectedClient = (client: Client, context: IDropsContext) => {
    context.dispatch({
      type: DropsActionType.SET_SELECTED_CLIENT,
      payload: client,
    });
  };
  
  export const clearSelectedClient = (context: IDropsContext) => {
    context.dispatch({ type: DropsActionType.CLEAR_SELECTED_CLIENT });
  };