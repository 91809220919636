import { FC } from "react";

import Select, { StylesConfig, components, OptionProps, SingleValueProps, SingleValue } from 'react-select';

/* interfaces */
import { GroupedOption, Option } from './dropdown.interfaces'

import './dropdown.css';


interface Props {
    data: Option[] | GroupedOption[];
    placeholder: string;
    isDisabled: boolean;
    onChange(value: Option): void;
    value: Option | null;
    className?:string;
}

/* 
    DropDown takes data from API, 
    the placeholder name (project, department, employee) 
    and whether it is grouped
*/
const DropDown = ({ data, placeholder, isDisabled, onChange, value, className }: Props) => {
    /* styling */
    const colourStyles: StylesConfig<Option, false, GroupedOption> = {
        control: (styles, { isFocused, isDisabled }) => ({
            ...styles,
            backgroundColor: isDisabled? '#fafafa' : isFocused ? 'white' : '#F2F2F2',
            color: 'black',
            border: isDisabled? '1px solid #fafafa' : '1px solid #F2F2F2',
            boxShadow: 'none',
            '&:hover': {
                border: '1px solid #F2F2F2',
            },
            width: '100%',
            height: '52px'
        }),
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            return {
                ...styles,

                backgroundColor: isDisabled
                    ? '#fafafa'  
                    : isSelected
                        ? '#F2F2F2'
                        : isFocused
                            ? '#6dc72c3b'
                            : undefined,

                color: isDisabled? '#a8a8a8' : isFocused ? 'black' : '',
                cursor: isDisabled ? 'not-allowed' : 'default',
                fontWeight: data.grouplabel ? '900' : '',


                ':active': {
                    ...styles[':active'],
                    backgroundColor: !isDisabled
                        ? isSelected
                            ? '#F2F2F2'
                            : '#F2F2F2'
                        : '#F2F2F2',
                },
                ':before': {
                    backgroundColor: '#F2F2F2',
                },
            };
        },
        groupHeading: (styles) => ({
            ...styles,
            color: 'black',
            fontWeight: '900',
            fontSize: '14px'
        }),
    }
    /* 
        handle change: based on what option was selected, index out that value
        from the original data structure. Call onChange and pass the selected
        option and the indexed value from the original data structure. Put in place
        because of react-select's required structure and the data we need
        to send to the api
    */
    const handleChange = (value: SingleValue<Option>) => {
        if (value != null){
            onChange(value)
        }
    }


    /*
        create archive tag
    */
    const SingleValue: FC<SingleValueProps<Option, false, GroupedOption>> | undefined = (props) => {
        let isArchived = props.data.archive ? 'Archived' : '';
        
        return (
            <components.SingleValue {...props}>
                <span>{props.data.label}</span> <span className='archiveLabel'>{isArchived}</span>
            </components.SingleValue>
        );
    };

    const Option: FC<OptionProps<Option, false, GroupedOption>> = (props) => {
        let isArchived = props.data.archive ? 'Archived' : '';

        return (
            <components.Option {...props}>
                <span>{props.data.label}</span> <span className='archiveLabel'>{isArchived}</span>
            </components.Option>
        );
    };

    return (
        <div className='dropdowndiv'>
            <Select<Option, false, GroupedOption>
                options={data}
                placeholder={placeholder}
                styles={colourStyles}
                className={`select ${className} disabled`}
                onChange={handleChange}
                value={value}
                isDisabled={isDisabled}
                getOptionLabel={(options) => {
                    return `${options.label} ${options.archive}`;
                }}
                components={{ Option, SingleValue }}
            />
        </div>
    );
}

export default DropDown;