import { sortTimeEntryBy } from "../components/ReportTimeEntries/utility";
import { defaultClient } from "../models/Client";
import { IDropsState, DropsStateSlice, initialState } from "../models/Context";
import { defaultProject } from "../models/Projects";
import { defaultTimeEntry } from "../models/TimeEntry";
import { DropsActionType } from "./Actions";
export interface IDropsAction {
  type: DropsActionType;
  payload?: DropsStateSlice;
}

export const DropsReducer = (state: IDropsState, action: IDropsAction) => {
  switch (action.type) {
    case "USER_LOGIN":
      return { ...state, currentUser: action.payload } as IDropsState;
    case "USER_LOGOUT":
      localStorage.clear();
      return initialState;
    case "GET_CLIENTS":
      return { ...state, clients: action.payload } as IDropsState;
    case "ADD_PROJECT":
      return {
        ...state,
        projects: [...state.projects, action.payload],
      } as IDropsState;
    case "GET_PROJECTS":
      return { ...state, projects: action.payload } as IDropsState;
    case "SET_TIME_ENTRIES":
      return { ...state, timeEntries: action.payload } as IDropsState;
    case "CLEAR_TIME_ENTRIES":
      return { ...state, timeEntries: [] } as IDropsState;
    case "SET_SELECTED_CLIENT":
      return { ...state, selectedClient: action.payload } as IDropsState;
    case "CLEAR_SELECTED_CLIENT":
      return { ...state, selectedClient: defaultClient } as IDropsState;
    case "SET_SELECTED_PROJECT":
      return { ...state, selectedProject: action.payload } as IDropsState;
    case "CLEAR_SELECTED_PROJECT":
      return { ...state, selectedProject: defaultProject } as IDropsState;
    case "SET_SELECTED_TIME_ENTRY":
      return { ...state, selectedTimeEntry: action.payload } as IDropsState;
    case "CLEAR_SELECTED_TIME_ENTRY":
      return { ...state, selectedTimeEntry: defaultTimeEntry } as IDropsState;
    case "SET_EMPLOYEES":
      return { ...state, employees: action.payload } as IDropsState;
    case "SET_SELECTED_DATE":
      return { ...state, selectedDate: action.payload } as IDropsState;
    case "OPEN_EDIT_ENTRY_MODAL":
      return { ...state, editEntryModal: true } as IDropsState;
    case "CLOSE_EDIT_ENTRY_MODAL":
      return { ...state, editEntryModal: false } as IDropsState;
    case "SET_CALENDAR_CONFIRMATIONS":
      return { ...state, calendarConfirmations: action.payload} as IDropsState;
    case "CLEAR_CALENDAR_CONFIRMATIONS":
      return { ...state, calendarConfirmations: []} as IDropsState;
    default:
      return state;
  }
};
