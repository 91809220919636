import React, { useContext, useEffect, useState } from "react";
import DatePicker, { ReactDatePickerCustomHeaderProps } from "react-datepicker";
import { CalendarDayState } from "./CalendarDay/calendarDayState";
import { CalendarHeader } from "./CalendarHeader/calendarHeader";
import { CalendarDay } from "./CalendarDay/calendarDay";
import { formatDate, isSameDay } from "./utility";
import "./calendar.css";
import DropsContext from "../../models/Context";
import { getConfirmationMonth } from "../../api/Confirmation";
import { initialUser } from "../../models/User";

export type CalendarEntry = {
  date: Date;
  state: CalendarDayState;
};

type Props = {
  /** Pass an array of `CalendarEntry` objects for each date in the calendar */
  entries: Array<CalendarEntry>;
  /**
   * Called when a day is clicked. Passes the associated date object
   */
  onChange: (date: Date) => void;
  /** Opens the calendar to the specific date. */
  openToDate: Date;
};


export const Calendar = ({ entries, onChange, openToDate }: Props) => {
  const context = useContext(DropsContext);
  const {
    dropsState: { calendarConfirmations, currentUser },
  } = context;
  const [loading, setLoading] = useState<boolean>(false);
  useEffect(() => {
    if (
      calendarConfirmations.length == 0 &&
      !loading &&
      currentUser !== initialUser
    ) {
      setLoading(true);
      getConfirmationMonth(context)
        .then(() => setLoading(false))
        .catch((e) => console.log(e));
    }
  }, [currentUser]);
  const renderDayContents = (day: number, date: Date) => {
    const state = calendarConfirmations.find(
      (entry) => isSameDay(entry.Date, date) && date <= new Date()
    )?.Confirmed

    return (
      <CalendarDay
        date={date}
        state={state!==undefined ? state ? CalendarDayState.Confirmed
            : CalendarDayState.Unconfirmed : CalendarDayState.NotRequired
        }
      />
    );
  };

  const renderCustomHeader = ({
    date,
    changeYear,
    changeMonth,
    decreaseMonth,
    increaseMonth,
    prevMonthButtonDisabled,
    nextMonthButtonDisabled,
  }: ReactDatePickerCustomHeaderProps) => {
    return (
      <CalendarHeader
        date={date}
        onStepRight={increaseMonth}
        onStepLeft={decreaseMonth}
      />
    );
  };

  return (
    <div className="calendar-date-picker">
      <DatePicker
        inline
        onChange={onChange}
        onMonthChange={onChange}
        openToDate={openToDate}
        renderDayContents={renderDayContents}
        renderCustomHeader={renderCustomHeader}
      />
    </div>
  );
};
