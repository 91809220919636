import { Notification } from "./settings.interfaces"

type NotifTypes = 'type' | 'day' | 'time'

interface Props {
    userNotifs:Notification[];
    notif:Notification;
    value: string;
    type: NotifTypes
}

export const updateNotifications = ({userNotifs, notif, value, type}:Props) => {
    let tempNotif:Notification[] = []
    for (let x of userNotifs) {
        if (x === notif) {
          x[type] = value
          tempNotif.push(x)
        } else {
          tempNotif.push(x)
        }
      }

    return tempNotif
}