import { useState, useEffect } from 'react';

import './addbutton.css'

interface Props {
  disabled: boolean;
  labeltext: String;
  onClick():void;
  className?:string
}

const AddButton = ({disabled, labeltext, className, onClick}:Props) => {
  const [isdisabled, setIsDisabled] = useState(Boolean);
  const [label, setLabel] = useState(String);

  useEffect(() => {
    setIsDisabled(disabled)
    setLabel(labeltext.toUpperCase())
  }, [])

  return (
    <div className="addbutton-container">
      <button 
        className={isdisabled ? `disabled-light addbutton ${className}` : `enabled addbutton ${className}`}
        onClick={onClick}
      >{label}</button>
    </div>
  );
}

export default AddButton;
