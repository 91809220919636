import { useContext } from 'react'
import DropsContext from '../../models/Context'
import './timeEntryCell.css'
import {TimeEntryData} from './timeSummary'

type Props = {
  /** Pass a formatted string for the number of hours worked. */
  hours: string,
  /** Pass a description for this block of time. */
  description: string,
  }

export const TimeEntryCell = ({hours, description}: Props)=> {
  const context = useContext(DropsContext)
  return(
    <>
    <span>{hours}</span>
    <p>{description}</p>
    </>
    )
}
