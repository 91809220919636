import { Option, GroupedOption } from "../dropdown.interfaces"
/*
 data is already configured so that it can be directly
 plugged into dropdown.tsx
*/

const data:Option[] | GroupedOption[] = [
    {value: "light", label:"System Default", grouplabel:false},
    {value: "dark", label:"Dark Mode", grouplabel:false},
]

export default {data}