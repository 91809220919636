import { useNavigate } from 'react-router-dom';
import { GoogleLogout } from "react-google-login"
import "./logout.css"
import { DropsActionType } from '../../context/Actions';
import { useContext } from 'react';
import DropsContext from '../../models/Context';

let clientVar: string | undefined = process.env.REACT_APP_GOOGLE_CLIENT_ID;
let clientID = typeof clientVar === 'string' ? clientVar : ""

const LogoutButton = () => {
    const context = useContext(DropsContext);
const {dispatch, dropsState} = context; 
    const userEmail = localStorage.getItem('email');
    let navigate = useNavigate();

    const onSuccess = () => {
        localStorage.removeItem('name');
        localStorage.removeItem('email');
        localStorage.removeItem('profileImg');
        dispatch({
            type: DropsActionType.USER_LOGOUT
          });
        let path = `/login`; 
        navigate(path);
    }

    return (
        <div className="google-logout App">
            <span className="logout-div d-flex flex-row align-items-baseline justify-content-center">
                <p className='email'>{userEmail}</p>
                <div id="signInButton">
                    <GoogleLogout
                        clientId={clientID}
                        onLogoutSuccess={onSuccess}
                        render={(renderProps) => (
                            <button onClick={renderProps.onClick} disabled={renderProps.disabled} className='logout'>
                                LOG OUT
                            </button>
                        )}
                    />
                </div>
            </span>
        </div>
    );
}

export default LogoutButton;
