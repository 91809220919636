import React, { useState } from 'react';
import TimeInput from './timeinput';

interface Props {
    time: string;
    onBlur(time:string):void;
    showError?: boolean
    label?: string
    showLabel?: boolean
    placeholder?:string
    showPlaceholder?:boolean
    isDisabled?: boolean
}

const TimeInputMain = ({time, showError, label, showLabel, placeholder, showPlaceholder, isDisabled, onBlur}:Props) => {
    const [displayTime, setDisplayTime] = useState<string>(time)
    const [error, setError] = useState<boolean>(false)
    const onChangeTime = (displayTime:React.ChangeEvent<HTMLInputElement> | string) => {
        let temp = typeof displayTime === 'string' ? displayTime : displayTime.target.value

        temp.length < 1 ? setError(true) : setError(false)
        setDisplayTime(temp)
    }
    label = label === undefined || label.length < 1 ? 'TIME' : label;
    placeholder = showPlaceholder === false ? '' :  placeholder === undefined || placeholder.length < 1 ? 'Enter A Time' : placeholder
    isDisabled = isDisabled === undefined ? false : isDisabled;

    
    return (
        <div>
            <h3 className={ showLabel ? '' : 'd-none' }>
                {label}
            </h3>
            <TimeInput 
                time={displayTime}
                onChange={(e) => onChangeTime(e)}
                onBlur={onBlur}
                placeholder={placeholder}
                isDisabled={isDisabled}
            />
            <p
                className={showError ? error ? '' : 'd-none': 'd-none'}
            >
                please enter a time
            </p>
        </div>
    )
}

export default TimeInputMain;