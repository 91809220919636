import Projects from "../pages/Admin/Projects/projects";
import { Confirmation } from "./Confirmation";
import { Project } from "./Projects";

export type TimeEntry = {
  Id?: number;
  UserId: number;
  Date: Date;
  ProjectId: number;
  DepartmentId?: number;
  Hours: number;
  Description: string;
  UserRowKey?: string;
  DepartmentRowKey?: string;
  ProjectRowKey?: string;
  ConfirmationId: number;
  Confirmation?: Confirmation;
  createdAt: Date;
  updatedAt: Date;
  deletedAt: Date | null;
  User?: { FirstName: string; LastName: string };
};

export type UserTimeEntry = {
  Confirmed: boolean;
  Description: string;
  Hours: number;
  ProjectId: number;
};

export type NewTimeEntryData = {
  ProjectId?: number;
  DepartmentId?: number;
  Hours: number;
  Description: string;
  TimeStamp: Date;
};

export type ProjectTimeEntry = Project & { timeEntries: TimeEntries };

export type TimeEntries = Array<TimeEntry>;

export const defaultTimeEntry = {
  UserId: 0,
  Date: new Date(),
  ProjectId: 0,
  Hours: 0.0,
  Description: "",
  TimeStamp: new Date(),
  ConfirmationId: 0,
  hours: "",
  description: "",
  createdAt: new Date(),
  updatedAt: new Date(),
  deletedAt: null,
};
