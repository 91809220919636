/* import component */
import DropDown from "../dropdown";

/* import types and interfaces */
import { Option } from "../dropdown.interfaces";

/* import mock data */
import data from "./data";


interface Props {
    onChange(option: Option | null): void;
    value: Option | null;
    isDisabled?: boolean;
    label?: string;
    className?: string;
    showLabel?:boolean;
}


const DropDownAppearence = ({value, onChange, className, isDisabled, label, showLabel}: Props) => {   
    /* configure label */  
    showLabel = showLabel === undefined ? true : showLabel
    label = label === undefined ? 'Appearance (Light/Dark Mode)' : label 


    /* get data from helper */
    let options = data['data']

    return (
        <div className={`${isDisabled} dropdown-appearence`}>
            <p className={showLabel===false ? 'd-none' : isDisabled ? 'component-header drop-down-disabled-label' : 'component-header'}>{label}</p>
            <DropDown 
                data={options} 
                placeholder={'System Default'}
                onChange={onChange} 
                value={value} 
                className={className}
                isDisabled={isDisabled ? isDisabled : false}
            />
        </div>
    );
}

export default DropDownAppearence;