import { useState, useEffect } from "react";

import "./archivebutton.css";

interface Props {
  disabled: boolean;
  labeltext: String;
  onClick(): void;
  className?: string;
}

const ArchiveButton = ({ disabled, labeltext, className, onClick }: Props) => {
  const [isdisabled, setIsDisabled] = useState(Boolean);
  const [label, setLabel] = useState(String);

  useEffect(() => {
    setIsDisabled(disabled);
    setLabel(labeltext.toUpperCase());
  }, []);

  return (
    <div className="archivebutton">
      <button
        className={
          isdisabled
            ? `disabled-light button ${className}`
            : `enabled button ${className}`
        }
        onClick={onClick}
      >
        <span>
          <i className="bi bi-archive" /> {label}
        </span>
      </button>
    </div>
  );
};

export default ArchiveButton;
