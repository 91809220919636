import React, { useContext, useEffect, useState } from "react";
import { ProjectDataProps } from "../../../components/AddProject/addproject.interfaces";
import { DataStructure } from "../../../components/DropDown/dropdown.interfaces";

import "./projects.css";
import "../../../App.css";

import AddProject from "../../../components/AddProject/addproject";
import { Modal, Button } from "react-bootstrap";
import TextField from "../../../components/TextField/textfield";
import DropsContext from "../../../models/Context";
import { getExpandedClients } from "../../../api/Clients";
import { setSelectedProject } from "../../../context/DropsDispatch/SelectedProjectDispatch";
import { Clients } from "../../../models/Client";

interface ClientProp {
  clientName: string;
  clientID: string;
}

const Projects = () => {
  const context = useContext(DropsContext);
  const {
    dropsState: { clients },
  } = context;

  const [isArchivedView, setArchive] =
    useState<boolean>(false); /* boolean for state of search bar */
  const [searchTerm, setSearchTerm] =
    useState<string|undefined>(undefined); /* boolean for state of search bar */
  const [projectModal, showProjectModal] =
    useState<boolean>(
      false
    ); /* boolean signaling to open 'edit project' pop up */
  const [isClientPopUpOpen, setIsClientPopUpOpen] =
    useState<boolean>(
      false
    ); /* boolean signaling to open 'edit client name' pop up */
  const [editProject, setEditProject] =
    useState<boolean>(
      false
    ); /* boolean signaling whether 'edit project' pop up is for an archived project or not */
  const [data, setData] = useState<ProjectDataProps>({
    /* data to send to 'edit project' pop up */ client: null,
    project: null,
    isBillable: false,
    projectLead: null,
  });
  const [client, setClient] = useState<ClientProp>({
    clientName: "",
    clientID: "",
  }); /* client object, holds client name and id  */
  const [newClientName, setNewClientName] =
    useState<string>(""); /* new client name */

  useEffect(() => {
    if (clients.length < 1) {
      getExpandedClients(context);
    }
  }, []);

  // useEffect(() => {
  //   if (isArchivedView) {
  //     setProjectsByClients(organizeArchivedProjects(projects))
  //     setOriginalPBC(organizeArchivedProjects(projects))
  //   } else {
  //     setProjectsByClients(organizeCurrentProjects(projects))
  //     setOriginalPBC(organizeCurrentProjects(projects))
  //   }
  // }, [isArchivedView])

  const getClientHTML = (clients: Clients) => {
    return clients.map((client) => {
      /* maps projects by clients */
      let clientID = client.Id;
      const sortedProjects = client.Projects?.sort((a, b) => {
        if (a.Name < b.Name) {
          return -1;
        } else {
          return 1;
        }
      }).map((project) => {
        let projectName = project.Name;
        let index = project.Name.indexOf(":") + 1;
        let archive = project.deletedAt === null ? false : true;
        projectName =
          project.Name.slice(0, index).toUpperCase() +
          project.Name.slice(index);
        if (archive === isArchivedView) {
          return (
            <div key={project.Id}>
              <p
                onClick={() => {
                  setSelectedProject(project, client, context);
                  showProjectModal(true);
                }}
                className="margin project-name"
              >
                {projectName}
              </p>
              <hr className="breaks" />
            </div>
          );
        }
      });
      if (client.Projects) {
        return (
          <div key={clientID}>
            <h1
              //onClick={isArchivedView ? undefined : ()=>handleEditClientName(client, clientID)}
              className="margin client-name"
            >
              {client.Name}
            </h1>
            {sortedProjects}
          </div>
        );
      }
    });
  };

  /* function for search queries */
  const getSearch = () => {
    let tempFilteredClients: Clients = [];
    if (searchTerm) {
      clients.map((client, index) => {
        if (client.Name.toLowerCase().includes(searchTerm.toLowerCase())) {
          tempFilteredClients.push(client);
        } else {
          client.Projects &&
            client.Projects.map((project) => {
              if (project.Name.toLowerCase().includes(searchTerm.toLowerCase())) {
                if (tempFilteredClients.includes(client)) {
                  tempFilteredClients[index].Projects?.push(project);
                } else {
                  tempFilteredClients.push({ ...client, Projects: [project] });
                }
              }
            });
        }
      });
    }
    return tempFilteredClients
  };
  /* event handlers for client pop up */
  const handleEditClientName = (name: string, id: string) => {
    /* open client pop up */
    setIsClientPopUpOpen(true);
    setClient({ clientName: name, clientID: id });
  };

  const handleCloseClientPopUp = () => {
    /* close client pop up */
    setIsClientPopUpOpen(false);
  };

  const onSubmitClientName = () => {
    /* submit client name */
    /* submit new client name here */
  };

  const handleClose = () => {
    /* close project popups */
    showProjectModal(false);
  };

  /* event handlers for navigation */
  const toArchiveProjects = () => {
    setArchive(true);
    setEditProject(false);
  };

  const toProjects = () => {
    setArchive(false);
    setEditProject(true);
  };

  return (
    <div className="projects d-flex align-items-center flex-column">
      <h1 className={isArchivedView ? "d-none" : "page-header"}>Projects</h1>

      <div className={isArchivedView ? "projects-header" : "d-none"}>
        <h1>Archived Projects</h1>
      </div>
      <div className="d-flex flex-row justify-content-between button-container">
        <div className={isArchivedView ? "d-none" : ""}>
          <Button onClick={() => showProjectModal(true)} id={"addproject-btn"}>
            + ADD PROJECT
          </Button>
        </div>
        <div className={isArchivedView ? "d-none" : ""}>
          <Button id={"archiveproject-btn"} onClick={toArchiveProjects}>
            ARCHIVED PROJECTS
          </Button>
        </div>
        <div className={isArchivedView ? "" : "d-none"}>
          <Button id={"archiveproject-btn"} onClick={toProjects}>
            Back
          </Button>
        </div>
      </div>

      <div
        className={
          !!searchTerm
            ? "active searchbar-container d-flex align-items-center"
            : "searchbar-container d-flex align-items-center"
        }
      >
        <i className="bi bi-search" />
        <input
          type="search"
          name="search-project-archive"
          id="search-project-archive"
          placeholder="Search Clients and Projects"
          onChange={(e)=>{setSearchTerm(e.target.value)}}
          className={"input-searchbar"}
        />
      </div>

      <div className="projects-container d-flex justify-content-start">
        <div className={"project"}>{getClientHTML(searchTerm?getSearch():clients)}</div>
      </div>

      <Modal show={projectModal} onHide={handleClose} className="project-popup">
        <Modal.Body>
          <AddProject
            archived={isArchivedView}
            handleClose={handleClose}
            data={data}
          />
        </Modal.Body>
      </Modal>

      <Modal
        show={isClientPopUpOpen}
        onHide={handleCloseClientPopUp}
        className="client-name-popup"
      >
        <Modal.Body>
          <h1 className="popup-header">Edit Client Name</h1>
          <div className="d-flex justify-content-center">
            <TextField
              fieldText={
                client?.clientName === null
                  ? "Enter Client Name"
                  : client?.clientName
              }
              label={"Client"}
              isDisabled={false}
              isValid={true}
              onChange={(event) => setNewClientName(event.target.value)}
              showLabel={true}
              className={"client-name-textfield"}
            />
          </div>
          <div className="d-flex flex-row justify-content-end client-modal-buttons">
            <div>
              <Button
                variant="secondary"
                onClick={handleCloseClientPopUp}
                id="client-cancel-btn"
              >
                Cancel
              </Button>
            </div>
            <div>
              <Button
                variant="primary"
                onClick={onSubmitClientName}
                id="client-submit-btn"
              >
                Submit
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Projects;
