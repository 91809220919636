import context from "react-bootstrap/esm/AccordionContext";
import { Client } from "../../models/Client";
import { IDropsContext } from "../../models/Context";
import { Project } from "../../models/Projects";
import { DropsActionType } from "../Actions";

export const setSelectedProject = (project: Project, client: Client, context: IDropsContext) => {
  context.dispatch({
    type: DropsActionType.SET_SELECTED_PROJECT,
    payload: project,
  });
  context.dispatch({
    type: DropsActionType.SET_SELECTED_CLIENT,
    payload: client,
  });

};

export const clearSelectedProject = (context: IDropsContext) => {
  context.dispatch({ type: DropsActionType.CLEAR_SELECTED_PROJECT });
};
