import { DropsActionType } from "../context/Actions";
import { IDropsContext } from "../models/Context";
import { getTimeEntriesForUser } from "./TimeEntry";
import { dateToString } from "./utils";

let geturl: string | undefined = process.env.REACT_APP_BASE_URL;
let base_url = geturl !== undefined ? geturl : "";

export const setConfirmation = async (id: Number, context: IDropsContext) => {
  try {
    fetch(
      base_url +
        `/v1/confirmations/${id}`,
      {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
        },
      }
    ).then(()=>{getTimeEntriesForUser(context);getConfirmationMonth(context)});
  } catch (e) {
    console.log(e);
  }
};

export const getConfirmationMonth = async (context: IDropsContext) => {
  const {dispatch} = context
  try {
    fetch(
      base_url +
      `/v1/confirmations/month/${context.dropsState.currentUser.Id}/${dateToString(context.dropsState.selectedDate)}`,
      {
        method: 'GET',
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
        },
      }
    ).then((response)=>{
      if(response.status==200){
        return response.json();
      } else { return []}
    }).then((confirmations)=>{
      dispatch({
        type: DropsActionType.SET_CALENDAR_CONFIRMATIONS,
        payload: confirmations,
      });
    })
  } catch (e) {
    console.log(e)
  }
}