import { valueMulti, valueSingle } from '../DatePicker/datepicker';
import { Option } from "../DropDown/dropdown.interfaces"

const getWeek = (date:Date) => {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate() - date.getDate() + 5) 
}

const getMonth = (date:Date) => {
    return new Date(date.getFullYear(), date.getMonth() + 1, 0) 
}

export const calculateTimeFrame = (option:Option) => {

    switch (option.label) {
        case 'All Time':
            return [new Date('2004-10-1'), new Date()] as valueMulti
        case 'This Month':
            return [new Date(option.value), getMonth(new Date(option.value))] as valueMulti
        case 'Last Month':
            return [new Date(option.value), getMonth(new Date(option.value))] as valueMulti
        case 'This Week':
            return [new Date(option.value), getWeek(new Date(option.value))] as valueMulti
        case 'Last Week':
            return [new Date(option.value), getWeek(new Date(option.value))] as valueMulti
        case 'Yesterday':
            return new Date(option.value) as valueSingle
        default:
            return new Date(option.value) as valueSingle
    }
        
}