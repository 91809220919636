import { Option, GroupedOption } from "../dropdown.interfaces"
/*
 data is already configured so that it can be directly
 plugged into dropdown.tsx
*/

const getStartOfWeek = () => {
var day = new Date().getDay(),
      diff = new Date().getDate() - day + (day == 0 ? -6:0); // adjust when day is sunday
  return new Date(new Date().setDate(diff));
}

const today = new Date()
const yesterday = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1)
const thisMonth = new Date(today.getFullYear(), today.getMonth(), 1)
const lastMonth = new Date(today.getFullYear(), today.getMonth()-1, 1)
const thisWeek = new Date(getStartOfWeek())
const lastWeek = new Date(thisWeek.getFullYear(), thisWeek.getMonth(), thisWeek.getDate() - 7)

const data:Option[] | GroupedOption[] = [
    {value: "all", label:"All Time", grouplabel:false},
    {value: today.toString(), label:"Today", grouplabel:false},
    {value: yesterday.toString(), label:"Yesterday", grouplabel:false},
    {value: thisWeek.toString(), label:"This Week", grouplabel:false},
    {value: lastWeek.toString(), label:"Last Week", grouplabel:false},
    {value: thisMonth.toString(), label:"This Month", grouplabel:false},
    {value: lastMonth.toString(), label:"Last Month", grouplabel:false},
]

export default {data}