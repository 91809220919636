const departments = [
    {
        "id": 38,
        "name": "Admin",
        "managerId": 193
    },
    {
        "id": 39,
        "name": "QA/Testing",
        "targetBillableHours": 6.5,
        "targetHours": 7.5,
        "billable": true,
        "managerId": 193
    },
    {
        "id": 40,
        "name": "Server Dudes",
        "targetBillableHours": 6,
        "targetHours": 7.5,
        "billable": true,
        "managerId": 193
    },
    {
        "id": 41,
        "name": "iOS",
        "targetBillableHours": 6.5,
        "targetHours": 7.5,
        "billable": true,
        "managerId": 203
    },
    {
        "id": 42,
        "name": "Project Managers",
        "targetBillableHours": 4.5,
        "targetHours": 7,
        "billable": true,
        "managerId": 205
    },
    {
        "id": 43,
        "name": "Android",
        "targetBillableHours": 6.5,
        "targetHours": 7.5,
        "billable": true,
        "managerId": 207
    },
    {
        "id": 44,
        "name": "Design",
        "targetBillableHours": 6.5,
        "targetHours": 7.5,
        "billable": true,
        "managerId": 200
    },
    {
        "id": 45,
        "name": "Connected Devices"
    },
    {
        "id": 46,
        "name": "Full Stack"
    }
]

const users = [  
    {
        "id": 286,
        "bcUserID": 1,
        "bcFirstName": "Brittany",
        "bcLastName": "Tisby",
        "bcApiToken": "",
        "bcEmail": "mtisby@mercuryintermedia.com",
        "bcAdministrator": false,
        "bcAvatarUrl": "http://cdn.37img.com/global/missing/avatar.gif?r=3",
        "bcDeleted": true,
        "bcCreatedAt": "2022-02-04T21:45:52.000Z",
        "departmentId": 39,
        "confirmations": false,
        "timeZone": "America/Chicago",
        "admin": false
    },
    {
        "id": 286,
        "bcUserID": 2,
        "bcFirstName": "Lynn",
        "bcLastName": "Tisby",
        "bcApiToken": "",
        "bcEmail": "mtisby@mercuryintermedia.com",
        "bcAdministrator": false,
        "bcAvatarUrl": "http://cdn.37img.com/global/missing/avatar.gif?r=3",
        "bcDeleted": false,
        "bcCreatedAt": "2022-02-04T21:45:52.000Z",
        "departmentId": 39,
        "confirmations": false,
        "timeZone": "America/Chicago",
        "admin": false
    },
    {
        "id": 286,
        "bcUserID": 3,
        "bcFirstName": "Kev",
        "bcLastName": "Tisby",
        "bcApiToken": "",
        "bcEmail": "mtisby@mercuryintermedia.com",
        "bcAdministrator": false,
        "bcAvatarUrl": "http://cdn.37img.com/global/missing/avatar.gif?r=3",
        "bcDeleted": false,
        "bcCreatedAt": "2022-02-04T21:45:52.000Z",
        "departmentId": 40,
        "confirmations": false,
        "timeZone": "America/Chicago",
        "admin": false
    },
    {
        "id": 286,
        "bcUserID": 4,
        "bcFirstName": "Mark",
        "bcLastName": "Tisby",
        "bcApiToken": "",
        "bcEmail": "mtisby@mercuryintermedia.com",
        "bcAdministrator": false,
        "bcAvatarUrl": "http://cdn.37img.com/global/missing/avatar.gif?r=3",
        "bcDeleted": false,
        "bcCreatedAt": "2022-02-04T21:45:52.000Z",
        "departmentId": 40,
        "confirmations": false,
        "timeZone": "America/Chicago",
        "admin": false
    },
    {
        "id": 286,
        "bcUserID": 5,
        "bcFirstName": "George",
        "bcLastName": "Tisby",
        "bcApiToken": "",
        "bcEmail": "mtisby@mercuryintermedia.com",
        "bcAdministrator": false,
        "bcAvatarUrl": "http://cdn.37img.com/global/missing/avatar.gif?r=3",
        "bcDeleted": false,
        "bcCreatedAt": "2022-02-04T21:45:52.000Z",
        "departmentId": 41,
        "confirmations": false,
        "timeZone": "America/Chicago",
        "admin": false
    },
    {
        "id": 286,
        "bcUserID": 6,
        "bcFirstName": "Charles",
        "bcLastName": "Tisby",
        "bcApiToken": "",
        "bcEmail": "mtisby@mercuryintermedia.com",
        "bcAdministrator": false,
        "bcAvatarUrl": "http://cdn.37img.com/global/missing/avatar.gif?r=3",
        "bcDeleted": false,
        "bcCreatedAt": "2022-02-04T21:45:52.000Z",
        "departmentId": 38,
        "confirmations": false,
        "timeZone": "America/Chicago",
        "admin": false
    },
    {
        "id": 286,
        "bcUserID": 7,
        "bcFirstName": "Lisa",
        "bcLastName": "Tisby",
        "bcApiToken": "",
        "bcEmail": "mtisby@mercuryintermedia.com",
        "bcAdministrator": false,
        "bcAvatarUrl": "http://cdn.37img.com/global/missing/avatar.gif?r=3",
        "bcDeleted": false,
        "bcCreatedAt": "2022-02-04T21:45:52.000Z",
        "departmentId": 41,
        "confirmations": false,
        "timeZone": "America/Chicago",
        "admin": false
    },
    {
        "id": 286,
        "bcUserID": 8,
        "bcFirstName": "Ken",
        "bcLastName": "Tisby",
        "bcApiToken": "",
        "bcEmail": "mtisby@mercuryintermedia.com",
        "bcAdministrator": false,
        "bcAvatarUrl": "http://cdn.37img.com/global/missing/avatar.gif?r=3",
        "bcDeleted": false,
        "bcCreatedAt": "2022-02-04T21:45:52.000Z",
        "departmentId": 42,
        "confirmations": false,
        "timeZone": "America/Chicago",
        "admin": false
    },
    {
        "id": 286,
        "bcUserID": 9,
        "bcFirstName": "Daria",
        "bcLastName": "Tisby",
        "bcApiToken": "",
        "bcEmail": "mtisby@mercuryintermedia.com",
        "bcAdministrator": false,
        "bcAvatarUrl": "http://cdn.37img.com/global/missing/avatar.gif?r=3",
        "bcDeleted": false,
        "bcCreatedAt": "2022-02-04T21:45:52.000Z",
        "departmentId": 41,
        "confirmations": false,
        "timeZone": "America/Chicago",
        "admin": false
    }, 
    {
        "id": 285,
        "bcUserID": 10,
        "bcFirstName": "Chad",
        "bcLastName": "Tisby",
        "bcApiToken": "",
        "bcEmail": "mtisby@mercuryintermedia.com",
        "bcAdministrator": false,
        "bcAvatarUrl": "http://cdn.37img.com/global/missing/avatar.gif?r=3",
        "bcDeleted": false,
        "bcCreatedAt": "2022-02-04T21:45:52.000Z",
        "departmentId": 46,
        "confirmations": false,
        "timeZone": "America/Chicago",
        "admin": false
    },
    {
        "id": 285,
        "bcUserID": 11,
        "bcFirstName": "Mar",
        "bcLastName": "Tisby",
        "bcApiToken": "",
        "bcEmail": "mtisby@mercuryintermedia.com",
        "bcAdministrator": false,
        "bcAvatarUrl": "http://cdn.37img.com/global/missing/avatar.gif?r=3",
        "bcDeleted": false,
        "bcCreatedAt": "2022-02-04T21:45:52.000Z",
        "departmentId": 45,
        "confirmations": false,
        "timeZone": "America/Chicago",
        "admin": false
    },
    {
        "id": 286,
        "bcUserID": 12,
        "bcFirstName": "Jack",
        "bcLastName": "Tisby",
        "bcApiToken": "",
        "bcEmail": "mtisby@mercuryintermedia.com",
        "bcAdministrator": false,
        "bcAvatarUrl": "http://cdn.37img.com/global/missing/avatar.gif?r=3",
        "bcDeleted": false,
        "bcCreatedAt": "2022-02-04T21:45:52.000Z",
        "departmentId": 44,
        "confirmations": false,
        "timeZone": "America/Chicago",
        "admin": false
    },
    {
        "id": 286,
        "bcUserID": 13,
        "bcFirstName": "Jake",
        "bcLastName": "Tisby",
        "bcApiToken": "",
        "bcEmail": "mtisby@mercuryintermedia.com",
        "bcAdministrator": false,
        "bcAvatarUrl": "http://cdn.37img.com/global/missing/avatar.gif?r=3",
        "bcDeleted": false,
        "bcCreatedAt": "2022-02-04T21:45:52.000Z",
        "departmentId": 43,
        "confirmations": false,
        "timeZone": "America/Chicago",
        "admin": false
    }
]

export {users, departments};