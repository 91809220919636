import { Option, GroupedOption } from "../dropdown.interfaces"
/*
 data is already configured so that it can be directly
 plugged into dropdown.tsx
*/

const data:Option[] | GroupedOption[] = [
    {value: "same-day", label:"Same Day", grouplabel:false},
    {value: "next-day", label:"Next Day", grouplabel:false},
]

export default {data}