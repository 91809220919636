import { DropsActionType } from "../context/Actions";
import { IDropsContext } from "../models/Context";
import { ReportFiltersRequestBody } from "../models/Reports";
import { NewTimeEntryData, TimeEntries, TimeEntry } from "../models/TimeEntry";
import { getConfirmationMonth } from "./Confirmation";
import { dateToString } from "./utils";

let geturl: string | undefined = process.env.REACT_APP_BASE_URL;
let base_url = geturl !== undefined ? geturl : "";

export const getTimeEntriesReport = (
  startDate: Date,
  endDate: Date,
  context: IDropsContext,
  args: ReportFiltersRequestBody
) => {
  try {
    const {
      dropsState: { currentUser, selectedDate },
      dropsState,
      dispatch,
    } = context;
    fetch(
      base_url +
        `/v1/timeEntries/report/${currentUser.Id}/${dateToString(
          startDate
        )}/?endDate=${dateToString(endDate || startDate)}`,
      {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          projectId: args.projectId,
          clientId: args.clientId,
          departmentId: args.departmentId,
          userId: args.userId,
        } as ReportFiltersRequestBody),
      }
    )
      .then((response: Response) => {
        return response.json();
      })
      .then((timeEntries: TimeEntries) => {
        dispatch({
          type: DropsActionType.SET_TIME_ENTRIES,
          payload: timeEntries,
        });
      });
  } catch (e) {
    console.log("Time Entry Fetch Error: ", e);
  }
};

export const getTimeEntriesForUser = (context: IDropsContext) => {
  try {
    const {
      dropsState: { currentUser, selectedDate },
      dropsState,
      dispatch,
    } = context;
    fetch(
      base_url +
        `/v1/timeEntries/${currentUser.Id}/${dateToString(
          selectedDate
        )}`,
      {
        method: "GET",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((response: Response) => {
        return response.json();
      })
      .then((timeEntries: TimeEntries) => {
        dispatch({
          type: DropsActionType.SET_TIME_ENTRIES,
          payload: timeEntries,
        });
      });
  } catch (e) {
    console.log("Time Entry Fetch Error: ", e);
  }
};

export const postTimeEntryForUser = (context: IDropsContext) => {
  const {
    dropsState: { currentUser, selectedDate, selectedTimeEntry },
  } = context;
  fetch(
    base_url +
      `/v1/timeEntries/${currentUser.Id}/${dateToString(selectedDate)}`,
    {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ProjectId: selectedTimeEntry.ProjectId,
        Hours: selectedTimeEntry.Hours,
        Description: selectedTimeEntry.Description,
      } as NewTimeEntryData),
    }
  ).then(() => {
    getTimeEntriesForUser(context);
    getConfirmationMonth(context);
  });
};
export const putTimeEntryForUser = (context: IDropsContext) => {
  const {
    dropsState: { currentUser, selectedDate, selectedTimeEntry },
  } = context;
  fetch(base_url + `/v1/timeEntries/${selectedTimeEntry.Id}`, {
    method: "PUT",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      ...selectedTimeEntry,
    } as TimeEntry),
  }).then(() => {
    getTimeEntriesForUser(context);
    getConfirmationMonth(context);
  });
};

export const deleteTimeEntryForUser = (context: IDropsContext) => {
  const {
    dropsState: { currentUser, selectedDate, selectedTimeEntry },
  } = context;
  fetch(base_url + `/v1/timeEntries/${selectedTimeEntry.Id}`, {
    method: "DELETE",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
    },
  }).then(() => {
    getTimeEntriesForUser(context);
    getConfirmationMonth(context);
  });
};
