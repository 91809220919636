import { useState } from 'react';

import './confirmationDays.css';
const daydict = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

type ConfirmedDays = Array<string>;

interface Props {
    onChange(days:ConfirmedDays):void;
    value:ConfirmedDays;
    isDisabled: boolean;
}

const ConfirmationDays = ({value, isDisabled, onChange}:Props) => {

    const changeDays = (day: string) => {
        if (!(value.includes(day))) {
            let add = [...value, day]
            onChange(add)

        } else {
            let remove = value.filter(days => days !== day)
            onChange(remove)
        }
    }

    const dayBtns = daydict.map((day) => {
        let activebtn = value.includes(day) ? 'dayBtn activeDayBtn' : 'dayBtn';
        let disabledbtn = isDisabled? 'dayBtn-inactive' : '';
        let classes = `${activebtn} ${disabledbtn}`

        return (
            <button
                value={day}
                onClick={() => changeDays(day)}
                className={classes}
                id={day}
                key={day}
            >{day[0]}</button>
        )
    })


    return (
        <div className="confirmation-days-container">
            <h5 className={isDisabled ? 'disabled-header component-header' : 'component-header'}>Required Confirmation Days</h5>
            {dayBtns}
        </div>
    );
}

export default ConfirmationDays;
