export type Project = {
    Id: number,
    Name: string,
    ClientId: number,
    updatedAt:Date,
    createdAt:Date
    deletedAt?:Date,
    AdminId?: number,
    RowKey?: string,
    AdminRowKey?: string,
    IsBillable: boolean,
}

export type NewProject = {
    Name: string,
    ClientId: number,
    AdminId?: number,
    RowKey?: string,
    AdminRowKey?: string,
    isBillable?: boolean,
}

export type Projects = Array<Project>;
export type ProjectSubmissions = Array<NewProject>;

export const defaultProject: Project = {
    Id: 0,
    Name: '',
    ClientId: 0,
    createdAt: new Date(),
    updatedAt: new Date(),
    IsBillable: false
}