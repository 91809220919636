import React, { createContext, Dispatch } from "react";
import { IDropsAction } from "../context/DropsReducer";
import { Client, Clients, defaultClient } from "./Client";
import { Confirmations } from "./Confirmation";
import { defaultProject, Project, Projects } from "./Projects";
import { defaultTimeEntry, TimeEntries, TimeEntry } from "./TimeEntry";
import { initialUser, User } from "./User";

export interface IDropsContextProvider {
  children: React.ReactNode;
}

export interface IDropsState {
  currentUser: User;
  clients: Clients;
  projects: Projects;
  departments: any; //Array of Departments
  timeEntries: TimeEntries; //This will update alot
  employees?: User[];
  selectedDate: Date;
  selectedTimeEntry: TimeEntry;
  selectedProject: Project;
  selectedClient: Client;
  editEntryModal: boolean
  calendarConfirmations: Confirmations
}

export type DropsStateSlice =
  | User
  | Clients
  | Client
  | Projects
  | Project
  | TimeEntries
  | TimeEntry
  | Confirmations
  | Date
  | number
  | boolean
  | null;

export const initialState: IDropsState = {
  currentUser: initialUser,
  clients: [],
  projects: [],
  departments: "",
  timeEntries: [],
  selectedDate: new Date(),
  selectedTimeEntry: defaultTimeEntry,
  selectedProject: defaultProject,
  selectedClient: defaultClient,
  editEntryModal: false,
  calendarConfirmations: [],
};
export interface IDropsContext {
  dropsState: IDropsState;
  dispatch: Dispatch<IDropsAction>;
}

const DropsContext = createContext<IDropsContext>({
  dropsState: initialState,
  dispatch: () => {},
});

export default DropsContext;
