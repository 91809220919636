import React, {MouseEvent} from 'react'
import './nextButton.css'

type Props = {
  onClick: () => void,
}

export const NextButton = ({onClick}: Props) => (
  <button onClick={(event: MouseEvent<HTMLElement>) => onClick()} className="next-btn">
    <svg width="9" height="15" viewBox="0 0 9 15" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.945 7.664c0 .224-.041.43-.125.617a1.818 1.818 0 0 1-.406.555l-5.469 5.351c-.265.26-.588.391-.968.391-.256 0-.49-.062-.704-.187a1.427 1.427 0 0 1-.511-.508 1.331 1.331 0 0 1-.192-.696c0-.39.151-.736.453-1.039l4.664-4.492-4.664-4.484C.721 2.88.57 2.539.57 2.148c0-.255.064-.488.192-.699.127-.21.298-.379.511-.504.214-.125.448-.187.704-.187.38 0 .703.13.968.39l5.469 5.344c.188.182.323.367.406.555.084.187.125.393.125.617z" fill="#000" fillRule="nonzero"/>
    </svg>
  </button>
)