import { render } from "react-dom";
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  Outlet,
  useNavigate,
} from "react-router-dom";
import { Login as sendToken } from "./api/Auth";
/* style sheets */
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "./index.css";
import "./App.css";

/* import pages*/
import Login from "./pages/Login/login";
import Time from "./pages/Time/time";
import Settings from "./pages/Settings/settings";
import Reports from "./pages/Reports/ProjectReports/reports";
import Employees from "./pages/Admin/Employees/employees";
import Departments from "./pages/Admin/Departments/departments";
import Projects from "./pages/Admin/Projects/projects";
import Navigation from "./components/Navbar/navigation";

/* import web vitals */
import reportWebVitals from "./reportWebVitals";
import { DropsProvider } from "./context/DropsContext";
import { useContext } from "react";
import DropsContext, { initialState } from "./models/Context";

/* Add Route Protected */
interface ProtectedRoutesChildren {
  children: JSX.Element;
}

const ProtectedRoute = ({ children }: ProtectedRoutesChildren) => {
  const context = useContext(DropsContext);
  const { dropsState } = context;
  const token = localStorage.getItem("token");
  
  //This Logic could look better i think
  if( token === null){
    return <Navigate to={'/login'} replace/>
  }
  if(dropsState.currentUser === initialState.currentUser){
      sendToken(token, context)
  }
  return children;
};

/* Format a Layout: All pages but the login page should display the navigation bar */
const Layout = () => {
  return (
    <div>
      <Navigation />
      <div>
        <Outlet />
      </div>
    </div>
  );
};

const rootElement = document.getElementById("root");
render(
  <DropsProvider>
    <BrowserRouter basename="/">
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route
            path="/time"
            element={
              <ProtectedRoute>
                <Time />
              </ProtectedRoute>
            }
          />
          <Route
            path="/settings"
            element={
              <ProtectedRoute>
                <Settings />
              </ProtectedRoute>
            }
          />
          <Route
            path="/reports"
            element={
              <ProtectedRoute>
                <Reports />
              </ProtectedRoute>
            }
          />
          <Route
            path="/admin/employees"
            element={
              <ProtectedRoute>
                <Employees />
              </ProtectedRoute>
            }
          />
          <Route
            path="/admin/departments"
            element={
              <ProtectedRoute>
                <Departments />
              </ProtectedRoute>
            }
          />
          <Route
            path="/admin/projects"
            element={
              <ProtectedRoute>
                <Projects />
              </ProtectedRoute>
            }
          />
          <Route index element={<Navigate to="/time" />} />
          <Route path="*" element={<Navigate to="/time" />} />
        </Route>
        <Route path="/login" element={<Login />} />
      </Routes>
    </BrowserRouter>
  </DropsProvider>,
  rootElement
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
