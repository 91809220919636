import { useContext } from "react";
import { TimeEntryCell } from "./timeEntryCell";
import "./clientSummary.css";
import { ProjectTimeEntry } from "../../models/TimeEntry";
import DropsContext from "../../models/Context";
import { setSelectedTimeEntry } from "../../context/DropsDispatch/TimeEntryDispatch";
import { showEditEntryModal } from "../../context/DropsDispatch/EditTimeEntryModalDispatch";

type Props = {
  /** The time summaries for a specific client. */
  summary: ProjectTimeEntry;
  /** A callback to grab time entry data **/
};
export const ClientSummary = ({ summary }: Props) => {
  const context = useContext(DropsContext);
  return (
    <div className="client-summary">
      <span className="heading">{summary.Name}</span>
      {/* <span className="subheading">{summary.ClientName.toUpperCase()}</span> */}
      {summary.timeEntries.map((timeEntry, index) => (
        <div
          onClick={() => {
            setSelectedTimeEntry(timeEntry, context);
            showEditEntryModal(context);
          }}
          className="time-entry-cell"
          key={`timeEntry-${index}`}
        >
          <TimeEntryCell
            hours={timeEntry.Hours.toString()}
            description={timeEntry.Description}
          />
        </div>
      ))}
    </div>
  );
};
