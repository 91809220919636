import { useEffect, useState } from 'react';
import { SingleValue } from 'react-select';
import { useResize } from '../../hooks/useResize';

import { valueMulti, valueSingle } from '../DatePicker/datepicker';
import { Option } from "../DropDown/dropdownhelper"
import { calculateTimeFrame } from './utils'

import DropDownTime from '../DropDown/TimeFrame/dropdownTimeFrame';
import MultiDatePickerContainer from '../DatePicker/MultiDatePicker/multidatepicker';

import "./timeframe.css"
import "../../App.css"
import { idText } from 'typescript';

interface Props{
  onChange(value: valueMulti | valueSingle, label:string):void;
  className?: string;
}

const TimeFrame = ({onChange, className}:Props) => {
  /* get the first and last day of the month */
  const date = new Date()
  var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
  var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

  const resize = useResize();

  const [startDate, setStartDate] = useState<valueSingle>(firstDay);
  const [endDate, setEndDate] = useState<valueSingle>(lastDay);
  const [active, setActive] = useState<number>(0);
  const [selectedVal, setSelectedVal] = useState<SingleValue<Option>>(null); 

  const handleChangeDropDown = (option: Option | null) => {
      setSelectedVal(option)
      if (option != null) {
        onChange(calculateTimeFrame(option), option.label)
      };
  };

  const handleChangeMulti = (dates:valueMulti) => {
    if(dates!=null){
      setStartDate(dates[0])
      setEndDate(dates[1])
    }

    onChange(dates, `${dates[0]?.toDateString()} ${dates[1] ? `to ${ dates[1]?.toDateString()}`: ''}`)
  };

  const handleRadioClick = (selcted:number) =>{
    if(1){
      handleChangeMulti([startDate,endDate])
    } else {
      handleChangeDropDown(selectedVal)
    }
    setActive(selcted)
  }

  return (
    <div className="timeframe" >
      <p className='component-header'>TIME FRAME</p>
        <div className='d-flex flex-row justify-content-between'>
          <div className='d-flex flex-row justify-content-end align-items-start timeframe-option' onClick={() => setActive(0)}>
              <input
                  type="radio"
                  name="dropdown"
                  id="dropdown"
                  checked={active===0}
                  onChange={() => handleRadioClick(0)}
                  className={'radio-btn'}
              />
              <DropDownTime
                value={selectedVal}
                onChange={handleChangeDropDown}
                className={
                  resize['tablet'] ?
                  'width-250' :
                  'width-408'
                }
                isDisabled={false}
                showLabel={false}
              />
          </div>
          <div className='d-flex flex-row justify-content-end align-items-end timeframe-option' onClick={() => setActive(1)}>
              <input
                  type="radio"
                  name="manual"
                  id="manual"
                  checked={active===1}
                  onChange={() => handleRadioClick(1)}
                  className={'radio-btn'}
              />
              <MultiDatePickerContainer
                  startDate={startDate}
                  endDate={endDate}
                  onChange={handleChangeMulti}
                  disabled={false}
                  className={
                    resize['tablet'] ?
                    'width-250' :
                    'width-408'
                  }
              />
          </div>
        </div>
    </div>
  );
}

export default TimeFrame;
