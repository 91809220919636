import { DropsActionType } from "../context/Actions";
import { Client, NewClient } from "../models/Client";
import { IDropsContext } from "../models/Context";

export const getClients = (context: IDropsContext) => {
  const { dispatch } = context;

  return fetch(process.env.REACT_APP_BASE_URL + "/v1/clients", {
    method: "Get",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error(response.statusText);
      } else {
        return response.json();
      }
    })
    .then((clients) => {
      dispatch({
        type: DropsActionType.GET_CLIENTS,
        payload: clients,
      });
    });
  };
  
  export const getExpandedClients = async (context: IDropsContext) => {
    const { dispatch } = context;
    const response = await fetch(
      process.env.REACT_APP_BASE_URL + "/v1/clients/expand",
      {
        method: "Get",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
        },
      }
      );
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      const clients = await response.json();
      dispatch({
        type: DropsActionType.GET_CLIENTS,
        payload: clients,
      });
      return clients
    };

export const createClientAndProject = async (
  client: NewClient,
  context: IDropsContext
) => {
  //const { dropsState, dispatch } = context;
  const response = await fetch(process.env.REACT_APP_BASE_URL + "/v1/clients", {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(client),})
    if (!response.ok) {
      throw new Error("Failed to create new client");
    } 
    getExpandedClients(context);
    return response.headers.get('ResourceId');
    }

export const updateClient = (client: Client, context: IDropsContext) => {
  return fetch(process.env.REACT_APP_BASE_URL + "/v1/clients", {
    method: "PUT",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(client),
  }).then(() => getExpandedClients(context));
};
export const deleteClient = (client: Client, context: IDropsContext) => {
  return fetch(process.env.REACT_APP_BASE_URL + "/v1/clients", {
    method: "DELETE",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(client),
  }).then(() => getExpandedClients(context));
};
