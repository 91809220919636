import { useState, useEffect } from "react";
import { Options } from "react-select";

/* import component */
import DropDown from "../dropdown";

/* import types and interfaces */
import { GroupedOption, Option } from "../dropdown.interfaces";

/* import mock data */
import { mobileEnabledData, mobileDisabledData } from "./data";


interface Props {
    onChange(option: Option | null): void;
    value: Option | null;
    placeholder?: string;
    isDisabled?: boolean;
    mobileEnabled: boolean
    label?: string;
    className?: string;
    showLabel?:boolean;
}


const DropDownNotification = ({value, onChange, className, mobileEnabled, isDisabled, label, showLabel}: Props) => {  
    const [ options, setOptions ] = useState<Option[] | GroupedOption[]>(mobileDisabledData) 
    /* configure label */  
    showLabel = showLabel === undefined ? true : showLabel
    label = label === undefined ? 'Notification' : label

    useEffect(()=> {
        if (mobileEnabled) {
            setOptions(mobileEnabledData)
        } else {
            setOptions(mobileDisabledData)
        }
    }, [mobileEnabled])


    return (
        <div className={`${isDisabled} dropdown-notifications`}>
            <p className={showLabel===false ? 'd-none' : isDisabled ? 'component-header drop-down-disabled-label' : 'component-header'}>{label}</p>
            <DropDown 
                data={options} 
                placeholder={'Notification'} 

                onChange={onChange} 
                value={value} 
                className={className}
                isDisabled={isDisabled ? isDisabled : false}
            />
        </div>
    );
}

export default DropDownNotification;