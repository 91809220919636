import React from 'react'
import {PreviousButton} from './previousButton'
import {NextButton} from './nextButton'
import './calendarHeader.css'

type Props = {
  date: Date,
  onStepLeft: () => void,
  onStepRight: () => void,
}

export const CalendarHeader = ({date, onStepLeft, onStepRight}: Props) => (
  <div className="calendar-header">
    <div
      className="calendar-header-stepper"
    >
      <PreviousButton onClick={onStepLeft} />
      <span 
        className="calendar-header-date"
      >
      {`${date.toLocaleString('default', { month: 'long' })} ${date.getFullYear()}`}
      </span>
      <NextButton onClick={onStepRight} />
    </div>
    <div className="calendar-header-days">
      <span>S</span>
      <span>M</span>
      <span>T</span>
      <span>W</span>
      <span>R</span>
      <span>F</span>
      <span>S</span>
    </div>
  </div>
)