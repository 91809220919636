import { useState, useEffect } from "react"

import { normalizeMobileNumber, stripMobileNumber } from './utils'

import './mobile.css'

interface Props {
    onBlur(mobileNumber:string, error:boolean):void
    mobileNumber: string
    showError?: boolean
    label?: string
    showLabel?: boolean
    placeholder?:string
    isDisabled?: boolean
}

const Mobile = ({onBlur, mobileNumber, showError, label, showLabel, placeholder, isDisabled}:Props) => {
    const [ phoneNumberTemp, setPhoneNumberTemp ] = useState<string>();
    const [ phoneNumberError, setPhoneNumberError ] = useState<boolean>(false);
    showLabel = showLabel === undefined ? true : showLabel;
    label = label === undefined || label.length < 1 ? 'MOBILE NUMBER' : label;
    placeholder = placeholder === undefined || placeholder.length < 1 ? 'Enter Mobile Number' : placeholder;
    isDisabled = isDisabled === undefined ? false : isDisabled;

    useEffect(() => {
        setPhoneNumberTemp(mobileNumber)
    }, [mobileNumber]) 

    const Validate = (e:React.ChangeEvent<HTMLInputElement>) => {
        let str = stripMobileNumber(e.target.value)
        const regex = new RegExp('^[0-9]*$')
    
        if (regex.test(str) && str.length === 10) {
          setPhoneNumberTemp(normalizeMobileNumber(str))
          setPhoneNumberError(false)
          onBlur(str, false)
        } else {
          setPhoneNumberError(true)
          onBlur('', true)
        }
    }

    return (
        <div className="mobile-text-field" role={'mobile-number'}>
            <label 
                htmlFor="phone-number"
                className={ showLabel ? 'header' : 'd-none'}
                >
                {label}
            </label>
            <input
              type="tel"
              onChange={(e)=>setPhoneNumberTemp(e.target.value)}
              onBlur={Validate}
              pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
              value={phoneNumberTemp}
              id="phone-number"
              placeholder={placeholder}
              disabled={isDisabled}
              className={isDisabled ? 'disable' : 'enable'}
            />
            <p className='phone-number-info'>Mobile number is required to select text notifications below.</p>
            <p 
                className={ showError === false ? 'd-none' : phoneNumberError ? 'error' : 'd-none'}
            >
                please enter a phone number
            </p>
        </div>
    )

}

export default Mobile