import React, { ChangeEvent } from 'react'
import {Form} from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import './switch.css'

type Props = {
  /** Determines if the switch is checked or not. */
  active: boolean,
  /** Called when the switch state is changed. */
  onChange: (e: ChangeEvent) => void,
}

export const Switch = ({active, onChange}: Props) => {
  return(
  <div className={active ? 'active': 'inactive'}>
    <Form className="switch form-check form-switch form-switch-md">
      <Form.Check
        onChange={e => onChange(e)}
        checked={active}
        type="switch"
        id="confirm-time-switch"
        label="Confirm Time"
      />
    </Form>
  </div>
)}