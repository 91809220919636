/**
 * Only allows quarter hour steps.
 */
export const restrictValue = (value: number, backspace: boolean): number => {
  const floatString = (value - Math.floor(value)).toFixed(2)
  if (backspace && (floatString == '0.20' || floatString == '0.50' || floatString == '0.70')) {
    return Math.floor(value)
  } else {
    return roundQuarterHour(value)
  }
}

/**
 * Round to the nearest quarter decimal.
 */
export const roundQuarterHour = (x: number): number => {
  return Math.round(x * 4) / 4
}