// import {CalendarEntry} from './calendar'
import {CalendarDayState} from './CalendarDay/calendarDayState'

/**
 * Chech that the date is the same excluding hours minutes and seconds. 
 */
export const isSameDay = (a: string, b: Date) => {
  const date = a.split("-")
  return (parseInt(date[2]) === b.getDate()
    && parseInt(date[1]) === b.getMonth()+1
    && parseInt(date[0]) === b.getFullYear()
  )
}

/**
 * Prefix a zero to single digit numbers
 */
export const formatDate = (day: number): string => {
  return day > 9 ? day.toString() : '0' + day.toString()
}

/** 
 * Calculate the first day to display in the calendar
 */
export const calculateDateZero = (date: Date): Date => {
  // Set to the first day of the month
  const initialCalendarDate = new Date(date.getTime())
  initialCalendarDate.setDate(1)
  
  // Calculate the first day in the calendar component
  const day = initialCalendarDate.getDay()
  initialCalendarDate.setDate(initialCalendarDate.getDate() - day)

  return initialCalendarDate
}

/**
 * Transform the time entries from the API to type of CalendarEntry
 */
// export const transformTimeEntries = (dayRange: Array<Date>, timeEntries: Array<{date: Date, confirmed: boolean}>): Array<CalendarEntry> => {
//   const dayEntries: Array<CalendarEntry> = dayRange.map(day => {

//     const entry = timeEntries?.find((entry: {date: Date, confirmed: boolean}) => isSameDay(entry.date, day))
    
//     let state = CalendarDayState.NotRequired
    
//     if (entry?.confirmed === true) {
//       state = CalendarDayState.Confirmed
//     } else if (entry?.confirmed === false) {
//       state = CalendarDayState.Unconfirmed
//     }

//     // Set hours and minutes to zero so that currentDay > day
//     day.setHours(0)
//     day.setMinutes(0)
//     return {date: day, state: state}
//   })

//   return dayEntries  
// }

/**
 * Converts the JSON response from the API to an array of `CalendarEntry` 
 */
// export const timesheetJSONToCalendarEntries = (json: Array<{userId: string, confirmed: boolean, date: string}>, date: Date): Array<CalendarEntry> => {
//   const minuteMillis = 60000
//   const initialDate = new Date(date.getTime())
//   const selectedDate = new Date(initialDate.getTime() + initialDate.getTimezoneOffset() * minuteMillis)
//   const initialDateInCalendar = calculateDateZero(selectedDate)
//   const daysInCalendar = 42

//   // Create day range
//   const dayRange = [...Array.from(Array(daysInCalendar).keys())].map(key => {
//     const date = new Date(initialDateInCalendar)
//     date.setDate(initialDateInCalendar.getDate() + key)
//     return date
//   })

//   const timeEntries = json?.map((datum: {date: string, confirmed: boolean, userId: string}) => {
//     const date = new Date(Date.parse(datum.date))
//     const dateAddTimezone = new Date(date.getTime() + date.getTimezoneOffset() * minuteMillis)
//     return ({
//       date: dateAddTimezone,
//       confirmed: datum.confirmed,
//     })
//   })
  
//   return transformTimeEntries(dayRange, timeEntries)
// }