/* react imports */
import React, { useContext, useEffect, useState } from "react";
import { useResize } from "../../hooks/useResize";

/* interfaces */
import { Option } from "../../components/DropDown/dropdown.interfaces";
import { TimePgTimeEntryData } from "./timehelper";

/* import components */
import { TimeEntryField } from "../../components/TimeEntryField/timeEntryField";
import DropDown from "../../components/DropDown/dropdown";

import TextField from "../../components/TextField/textfield";
import AddButton from "../../components/addButton/addbutton";
import { TimeEntrySummary } from "../../components/TimeEntrySummary/timeEntrySummary";
import { Modal } from "react-bootstrap";

/* import css */
import "./time.css";
import "../../App.css";
import DropsContext from "../../models/Context";
import {
  clearSelectedTimeEntry,
  getUserTime,
  setSelectedTimeEntry,
  submitUserTime,
} from "../../context/DropsDispatch/TimeEntryDispatch";
import { defaultTimeEntry, TimeEntry } from "../../models/TimeEntry";
import { Calendar } from "../../components/Calendar/calendar";
import { setSelectedDate } from "../../context/DropsDispatch/SelectedDateDispatch";
import { getProjectOptions, getSelectedProjectOption } from "../utils";
import {
  findProjectById,
  getSelectedClientOption,
} from "../Admin/Projects/utils/project";
import { isDisabled } from "@testing-library/user-event/dist/utils";
import { getExpandedClients } from "../../api/Clients";
import {
  clearSelectedProject,
  setSelectedProject,
} from "../../context/DropsDispatch/SelectedProjectDispatch";
import TimePagePopUp from "../../components/TimePagePopUp/TimePagePopUp";
import { hideEditEntryModal } from "../../context/DropsDispatch/EditTimeEntryModalDispatch";

const Time = () => {
  const context = useContext(DropsContext);
  const {
    dropsState: {
      clients,
      currentUser,
      selectedDate,
      selectedProject,
      selectedTimeEntry,
      timeEntries,
      editEntryModal,
    },
    dropsState,
  } = context;
  const [confirmation, setConfirmation] = useState<boolean>(false);

  useEffect(() => {
    clients.length < 1 && getExpandedClients(context);
    clearSelectedTimeEntry(context);
  }, [timeEntries]);

  useEffect(() => {
    getUserTime(context);
    //setTotalHours(getTotalHours(tempSummary));
  }, [currentUser, selectedDate]);

  useEffect(
    () => () => {
      clearSelectedProject(context);
    },
    [timeEntries]
  );
  /*
    Once calendar component is done get date from whatever they
    clicked on and reconfigure the day to fit this format 
    add a function later to do this; 

    For now only format's today
  */
  const today = () => {
    const offset = selectedDate.getDate() - new Date().getDate();
    let dateString = "";
    switch (offset) {
      case -1:
        dateString += "Yesterday, ";
        break;
      case 0:
        dateString += "Today, ";
        break;
      case 1:
        dateString += "Tomorrow, ";
        break;
      default:
        break;
    }
    dateString += selectedDate.toLocaleDateString("en-us", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
    return dateString;
  };

  const onTimeChange = (time: number | null) => {
    if (!!time || time == 0) {
      setSelectedTimeEntry({ ...selectedTimeEntry, Hours: time }, context);
    } else {
      setSelectedTimeEntry({ ...selectedTimeEntry, Hours: 0 }, context);
    }
  };

  const onProjectChange = (option: Option) => {
    const foundProject = findProjectById(option.value, context);
    if (foundProject !== null) {
      setSelectedTimeEntry(
        { ...selectedTimeEntry, ProjectId: foundProject.Id },
        context
      );
      //setSelectedProject(foundProject, defaultClient, context);
    }
  };

  const onDescriptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedTimeEntry(
      { ...selectedTimeEntry, Description: e.target.value },
      context
    );
  };

  const onConfirmChange = (value: boolean) => {
    setConfirmation(value);
  };

  const handleDateChange = (date: Date) => {
    setSelectedDate(date, context);
  };

  const onSubmit = () => {
    if (selectedTimeEntry.ProjectId !== 0 && selectedTimeEntry.Hours !== 0) {
      submitUserTime(context);
    }
  };

  const resize = useResize();

  return (
    <div className="timepg d-flex justify-content-center">
      <div
        className={
          resize["tablet"]
            ? "d-flex flex-column justify-content-center align-items-center timepg-container"
            : "d-flex flex-row align-items-start justify-content-center timepg-container"
        }
      >
        <div className="left-div">
          <Calendar
            entries={[]}
            openToDate={selectedDate}
            onChange={handleDateChange}
          />
        </div>
        <div className="right-div">
          <div className="time-form">
            <div className="today-container">
              <h1 className="today">{today()}</h1>
            </div>
            <div
              className={
                resize["tablet"]
                  ? "d-flex flex-column justify-content-center align-items-start"
                  : "d-flex flex-row justify-content-center align-items-baseline "
              }
            >
              <div className="time-entry-container">
                <div>
                  <p className="component-header">TIME</p>
                  <TimeEntryField
                    time={
                      editEntryModal || selectedTimeEntry.Hours == 0
                        ? null
                        : selectedTimeEntry.Hours
                    }
                    onChange={onTimeChange}
                    isDisabled={false}
                    className={
                      resize["mobileSM"]
                        ? "width-300"
                        : resize["mobile"]
                        ? "width-450"
                        : resize["tablet"]
                        ? "width-550"
                        : "width-128"
                    }
                  />
                </div>
              </div>
              <div className="dropdown-projects">
                <p className="component-header">Project</p>
                <DropDown
                  data={getProjectOptions(context)}
                  placeholder="Project"
                  value={
                    editEntryModal ? null : getSelectedProjectOption(context)
                  }
                  onChange={onProjectChange}
                  isDisabled={false}
                  className={
                    resize["mobileSM"]
                      ? "width-300"
                      : resize["mobile"]
                      ? "width-450"
                      : resize["tablet"]
                      ? "width-550"
                      : "width-450"
                  }
                />
              </div>
            </div>
            <div className="text-field-container">
              <TextField
                placeholder={"Enter Description"}
                fieldText={editEntryModal ? "" : selectedTimeEntry.Description}
                isValid={true}
                label={"Description"}
                isDisabled={false}
                className={
                  resize["mobileSM"]
                    ? "width-300"
                    : resize["mobile"]
                    ? "width-450"
                    : resize["tablet"]
                    ? "width-550"
                    : "width-610"
                }
                onChange={onDescriptionChange}
              />
            </div>
          </div>

          <div className="add-btn">
            <AddButton
              disabled={false}
              labeltext={"Add Time"}
              onClick={onSubmit}
            />
          </div>

          <div className="timeentry-container">
            <TimeEntrySummary/>
          </div>
        </div>
      </div>

      <Modal
        show={editEntryModal}
        onHide={() => hideEditEntryModal(context)}
        className={"time-pg-popup"}
        id={"time-pg-popup"}
      >
        <Modal.Body>
          <TimePagePopUp />
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Time;
