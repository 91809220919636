/* import component */
import DropDown from "../dropdown";

/* import types and interfaces */
import { GroupedOption, Option } from "../dropdown.interfaces";

/* import functions */
import { dropdownProjects } from "../configdata";
import { groupOptions, createOptions } from '../dropdownhelper';

/* import mock data */
import DropsContext from "../../../models/Context";
import { useContext, useEffect } from "react";
import { getProjects } from "../../../api/Projects";
import { getProjectOptions } from "../../../pages/utils";

interface Props {
    onChange(option: Option): void;
    value: number | null;
    grouped: boolean;
    placeholder?:string;
    isDisabled?: boolean;
    className?: string;
    showLabel?:boolean;
    label?: string;
}


const DropDownProjects = ({value, onChange, placeholder, className, showLabel, grouped, isDisabled, label}: Props) => {   
    const context = useContext(DropsContext)
    const {dropsState: {projects, selectedTimeEntry}, dispatch} = context;

    useEffect(()=>{
        if(projects.length===0){
            getProjects(context);
        }
    },[])

    /* configure label */
    showLabel = showLabel === undefined ? true : showLabel
    label = label === undefined ? 'Projects' : label

    /* reconfigure data to plug into the general dropdown component */ 
    let sortedData = dropdownProjects(projects)

    /* reconfigure original data for main dropdown component */
    let data = sortedData
    let dataArr = Object.keys(data);
    let selectedOption:Option | null = null;

    let options;
    if (grouped) {
        options = groupOptions({ dataArr, data });
        options.forEach((group: GroupedOption)=>{
            selectedOption = group.options.filter((option:Option)=>{return option.value==value?.toString()})[0];
            if(!!selectedOption){return selectedOption}
        })
    } else {
        options = createOptions({ dataArr, data })
        options.unshift({
            "value": "all",
            "label": "All Projects",
            "grouplabel": true,
            "archive": false
        })
        selectedOption = options.filter((opt: Option )=> opt.value === value?.toString())[0]

    }
    return (
        <div className="dropdown-projects">
            <p className={showLabel===false ? 'd-none' : isDisabled ? 'component-header drop-down-disabled-label' : 'component-header'}>Project</p>
            <DropDown 
                data={getProjectOptions(context)} 
                placeholder={placeholder ?? 'Projects'} 
                onChange={onChange} 
                value={selectedOption} 
                className={className}
                isDisabled={isDisabled ? isDisabled : false}
            />
        </div>
    );
}

export default DropDownProjects;