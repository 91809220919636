import React, { useEffect, useState } from 'react';

const useResize = () => {
  const [isMobileSM, setIsMobileSM] = useState(Boolean);
  const [isMobile, setIsMobile] = useState(Boolean);
  const [isTablet, setIsTablet] = useState(Boolean);

    useEffect(() => {
        const handleResize = () => {
          setIsMobileSM(window.innerWidth < 475);
          setIsMobile(window.innerWidth < 600);
          setIsTablet(window.innerWidth < 968);
        }

        window.addEventListener('resize', handleResize)
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    
    return {
      'mobileSM': isMobileSM,
      'mobile': isMobile, 
      'tablet': isTablet
    }
};

export {useResize}

