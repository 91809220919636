import React from 'react';

import { convertNum2Str, convertStr2Num } from './utils'

import "./timeinput.css"

interface Props {
    time: string
    onChange(displayTime:React.ChangeEvent<HTMLInputElement> | string):void
    onBlur(time:string):void
    placeholder?:string
    isDisabled?: boolean
}

const TimeInput = ({time, placeholder, isDisabled, onChange, onBlur}:Props) => {
   const Validate = () => { 
        let hoursMins:string;
        let displayTime:string;

        if (!(time.includes(":"))) {
            let temp = parseInt(time).toString()
            hoursMins =
             temp.length < 3 ? `${temp.slice(0,2)}:00` 
             : temp.length < 4 ? `${temp.slice(0,1)}:${temp.slice(1)}` 
             : `${temp.slice(0,2)}:${temp.slice(2)}`
        } else {
            let temp = time.trim()
            hoursMins = temp.toLowerCase().includes('p') 
                ? temp.slice(0, temp.toLowerCase().indexOf('p')) 
                : temp.toLowerCase().includes('a') 
                ?  temp.slice(0, temp.toLowerCase().indexOf('a')) 
                : temp.includes(":")
                ? temp
                : parseInt(temp).toString()
        }

        let timeOfDay:string = parseInt(hoursMins) > 12 || time.toLowerCase().includes("p") ? 'PM' : 'AM'

        if(isNaN(parseInt(hoursMins.split(":").join(""))) === false){
            let time = convertNum2Str(hoursMins.trim(), timeOfDay)
            displayTime = time
        } else {
            displayTime = ''
        }

        onChange(displayTime)
        onBlur(convertStr2Num(displayTime))
    }

    return (
        <div className='time-text-field'>
            <input 
                type="text" 
                value={ time }
                onChange={ onChange }
                onBlur={ Validate }
                placeholder={ placeholder }
                disabled={ isDisabled }
                className={ isDisabled ? 'disable' : 'enable' }
            />
        </div>
    );
}

export default TimeInput;