import { useState, useEffect } from 'react';

import './deleteButton.css';
import '../../App.css';

interface Props {
    disabled: boolean;
    labeltext: String;
    onClick():void;
    className?:string;
  }

const DeleteButton = ({disabled, labeltext, className, onClick}:Props) => {
    const [isdisabled, setIsDisabled] = useState(Boolean);
    const [label, setLabel] = useState(String);

    useEffect(() => {
        setIsDisabled(disabled)
        setLabel(`DELETE ${labeltext.toUpperCase()}`)
    }, [])

    return (
        <div className="deletebutton">
            <button
                className={isdisabled ? `disabled-light button ${className}` : `enabled button ${className}`}
                onClick={onClick}
            >
                <span className='deletebtn-contianer'>
                    <i className='bi bi-archive' />
                    {label}
                </span>
            </button>
        </div>
    );
}

export default DeleteButton;
