import { NewProject, Project, Projects } from "./Projects";

export type Client = {
    Id: number,
    Name: string,
    updatedAt:Date,
    createdAt:Date
    deletedAt?:Date | null,    
    Projects?: Projects
    NewProject?: NewProject
}
export type NewClient = {
    Name: string,
    Projects?: {
        Name: string,
        isBillable?: Boolean,
        AdminId?: number
    }
}

export type Clients = Array<Client>;

export const defaultClient = {
    Id: 0,
    Name: '',
    createdAt: new Date(),
    updatedAt: new Date()
}