export enum DropsActionType {
  USER_LOGIN = "USER_LOGIN",
  USER_LOGOUT = "USER_LOGOUT",
  GET_CLIENTS = "GET_CLIENTS",
  ADD_PROJECT = "ADD_PROJECT",
  GET_PROJECTS = "GET_PROJECTS",
  SET_TIME_ENTRIES = "SET_TIME_ENTRIES",
  CLEAR_TIME_ENTRIES = "CLEAR_TIME_ENTRIES",
  SET_SELECTED_CLIENT = "SET_SELECTED_CLIENT",
  CLEAR_SELECTED_CLIENT = "CLEAR_SELECTED_CLIENT",
  SET_SELECTED_PROJECT = "SET_SELECTED_PROJECT",
  CLEAR_SELECTED_PROJECT = "CLEAR_SELECTED_PROJECT",
  SET_SELECTED_TIME_ENTRY = "SET_SELECTED_TIME_ENTRY",
  CLEAR_SELECTED_TIME_ENTRY = "CLEAR_SELECTED_TIME_ENTRY",
  SET_EMPLOYEES = "SET_EMPLOYEES",
  SET_SELECTED_DATE = "SET_SELECTED_DATE",
  OPEN_EDIT_ENTRY_MODAL = "OPEN_EDIT_ENTRY_MODAL",
  CLOSE_EDIT_ENTRY_MODAL = "CLOSE_EDIT_ENTRY_MODAL",
  SET_CALENDAR_CONFIRMATIONS = "SET_CALENDAR_CONFIRMATIONS",
  CLEAR_CALENDAR_CONFIRMATIONS = "CLEAR_CALENDAR_CONFIRMATIONS"
}
