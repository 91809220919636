import { Option, GroupedOption } from "../dropdown.interfaces"
/*
 data is already configured so that it can be directly
 plugged into dropdown.tsx
*/

export const mobileEnabledData:Option[] | GroupedOption[] = [
    {value: "email", label:"Email", grouplabel:false},
    {value: "slack", label:"Slack", grouplabel:false},
    {value: "text", label:"Text", grouplabel:false, isDisabled:false},
]

export const mobileDisabledData:Option[] | GroupedOption[] = [
    {value: "email", label:"Email", grouplabel:false},
    {value: "slack", label:"Slack", grouplabel:false},
    {value: "text", label:"Text", grouplabel:false, isDisabled:true},
]
