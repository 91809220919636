import { useContext, useEffect, useState } from "react";

/* components */
import TextField from "../TextField/textfield";
import CheckBox from "../CheckBox/checkbox";
import { Button } from "react-bootstrap";
import ArchiveButton from "../ArchiveButton/addButton/archivebutton";

/* types and interfaces */
import { Option } from "../DropDown/dropdown.interfaces";
import { ProjectDataProps } from "./addproject.interfaces";

import "./addproject.css";
import { defaultClient, NewClient } from "../../models/Client";
import DropsContext, { IDropsContext } from "../../models/Context";
import { defaultProject, NewProject } from "../../models/Projects";
import {
  findClientById,
  getClientByName,
  getClientOptions,
  getSelectedClientOption,
} from "../../pages/Admin/Projects/utils/project";
import DropDown from "../DropDown/dropdown";
import {
  clearSelectedProject,
  setSelectedProject,
} from "../../context/DropsDispatch/SelectedProjectDispatch";
import {
  clearSelectedClient,
  setSelectedClient,
} from "../../context/DropsDispatch/SelectedClientDispatch";
import { createClientAndProject, getClients } from "../../api/Clients";
import { createProject, updateProject } from "../../api/Projects";
import { createClientAndUpdatProject } from "./utils";

interface Props {
  data: ProjectDataProps;
  handleClose(): void;
  archived: boolean;
  id?: string;
  className?: string;
}

const AddProject = ({ data, handleClose, archived }: Props) => {
  const context = useContext(DropsContext);
  const {
    dropsState: { selectedProject, selectedClient },
  } = context;
  const edit = selectedProject === defaultProject;
  const [active, setActive] = useState<number>(0);
  const [isArchived, setIsArchived] = useState<boolean>(archived);
  const [projectLead, setProjectLead] = useState<Option | null>();
  const [newClient, setNewClient] = useState<string>("");

  useEffect(
    () => () => {
      clearSelectedClient(context);
      clearSelectedProject(context);
    },
    []
  );

  /* placeholder for submitting data to backend */
  const createClient = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewClient(event.target.value);
  };

  /* get projectName from Textfield component */
  const setProjectName = (event: React.ChangeEvent<HTMLInputElement>) => {
    // const selectedProj = findProjectById(parseInt(event.target.value), context)
    // setProject(selectedProj);
  };

  /* get project lead from DropDownEmployees component */
  const getProjectLead = (option: Option | null) => {
    setProjectLead(option);
  };

  /* on submit send data to backend */
  const onSubmit = () => {
    const isNewProj: boolean = selectedProject.Id === 0;
    //this is probably okay if the indexes start at -2147483639 in prod
    const isNewClient: boolean = active === 1;
    if (selectedProject.Name === "") {
      handleClose();
      alert("Project name cannot be empty");
    }
    if (selectedClient.Name === "" && newClient === "") {
      handleClose();
      alert("Client name cannot be empty");
    }
    if (isNewProj && isNewClient) {
      const subClient: NewClient = {
        Name: newClient,
        Projects: {
          Name: selectedProject.Name,
          isBillable: selectedProject.IsBillable,
          AdminId: selectedProject.AdminId ? selectedProject.AdminId : null,
        } as NewProject,
      };
      createClientAndProject(subClient, context);
    } else if (!isNewProj && isNewClient) {
      createClientAndUpdatProject(newClient, context);
    } else if (isNewProj && !isNewClient) {
      createProject(
      {
        Name: selectedProject.Name,
        isBillable: selectedProject.IsBillable,
        ClientId: selectedClient.Id,
        AdminId: selectedProject.AdminId,
      },
        context
      );
    } else if (!isNewProj && !isNewClient) {
      updateProject(
        { ...selectedProject, ClientId: selectedClient.Id },
        context
      );
    } else {
      alert('Something went wrong')
    }
    handleClose();
  };

  const onArchive = () => {
    /* on archive, archive the individual employee */
    setIsArchived(true);
  };

  const onUnarchive = () => {
    /* on archive, archive the individual employee */
    setIsArchived(false);
  };
  useEffect(() => {
    if (active === 0) {
    }
  }, [active]);

  return (
    <div className="addproject">
      <h1 className="page-header">Add Project</h1>
      <div className="content-container d-flex flex-column justify-content-center">
        <div className="client-container d-flex flex-column align-items-start">
          <div className="header-div">
            <p
              className={
                isArchived
                  ? "disabled-header component-header"
                  : "component-header"
              }
            >
              Client
            </p>
          </div>
          <div className="d-flex flex-row client-div">
            <div
              className="d-flex flex-row client-option client-option-dropdown"
              onClick={() => setActive(0)}
            >
              <input
                type="radio"
                name="dropdown"
                id="dropdown"
                checked={active == 0}
                onChange={() => setActive(0)}
                className={"radio-btn"}
              />
              <div className={`${isArchived} dropdown-projects`}>
                <div className="dropdowndiv">
                  <DropDown
                    data={getClientOptions(context)}
                    placeholder={"Client"}
                    value={getSelectedClientOption(context)}
                    onChange={(e) => {
                      const clientFound = findClientById(
                        parseInt(e.value),
                        context
                      );
                      if (clientFound) {
                        setSelectedClient(clientFound, context);
                      }
                    }}
                    className="dropdown-client"
                    isDisabled={isArchived}
                  />
                </div>
              </div>
            </div>
            <div
              className="d-flex flex-row client-option client-option-text"
              onClick={() => setActive(1)}
            >
              <input
                type="radio"
                name="manual"
                id="manual"
                checked={active == 1}
                onChange={() => setActive(1)}
                className={"radio-btn"}
              />
              <TextField
                fieldText={newClient}
                placeholder={"Enter New Client"}
                label={""}
                isDisabled={isArchived}
                isValid={true}
                onChange={createClient}
                showLabel={false}
                className={"textfield-client"}
              />
            </div>
          </div>
        </div>
        <div className="project-div d-flex form-row align-items-end margin-sm">
          <div>
            <TextField
              fieldText={selectedProject.Name}
              placeholder="Enter Project Name"
              label={"Project"}
              isDisabled={isArchived}
              isValid={true}
              onChange={(e) => {
                setSelectedProject(
                  { ...selectedProject, Name: e.target.value },
                  selectedClient,
                  context
                );
              }}
              showLabel={true}
              className={"project-name"}
            />
          </div>
          <div>
            <CheckBox
              onClick={(e) => {
                setSelectedProject(
                  { ...selectedProject, IsBillable: e },
                  selectedClient,
                  context
                );
              }}
              labeltext={"Project is Billable"}
              isClicked={selectedProject.IsBillable}
              isDisabled={isArchived}
            />
          </div>
        </div>

        <div className="d-flex form-row project-div margin-sm">
          {/* <DropDownEmployees 
              value={projectLead} 
              onChange={getProjectLead} 
              isDisabled={isArchived}
              grouped={false}
              showLabel={true} 
              label={'Alternate Project Lead'} 
              className={'dropdown-employee'}
            /> */}
        </div>

        <div
          className={
            edit === false
              ? "margin-sm add-project-modal-buttons d-flex justify-content-end"
              : "add-project-modal-buttons d-flex align-items-center justify-content-between"
          }
        >
          <div className={edit === false ? "d-none" : ""}>
            <div>
              {/* for former employees */}
              <div
                className={
                  edit === false ? "d-none" : isArchived ? "" : "d-none"
                }
              >
                <ArchiveButton
                  disabled={false}
                  labeltext={"Unarchive Project"}
                  onClick={onUnarchive}
                />
              </div>
              {/* for current employees */}
              <div
                className={
                  edit === false ? "d-none" : isArchived ? "d-none" : ""
                }
              >
                <ArchiveButton
                  disabled={false}
                  labeltext={"Archive Project"}
                  onClick={onArchive}
                />
              </div>
            </div>
          </div>
          <div className="d-flex flex-row">
            <div>
              <Button
                variant="secondary"
                onClick={handleClose}
                id="add-project-cancel-btn"
              >
                Cancel
              </Button>
            </div>
            <div>
              <Button
                variant="primary"
                onClick={onSubmit}
                id={
                  isArchived
                    ? "add-project-submit-btn-disabled"
                    : "add-project-submit-btn"
                }
              >
                Submit
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddProject;
