import React from 'react'
import {CalendarDayState} from './calendarDayState'
import {ConfirmedSymbol} from './confirmedSymbol'
import {UnconfirmedSymbol} from './unconfirmedSymbol'
import {NotRequiredSymbol} from './notRequiredSymbol'
import {formatDate} from '../utility'
import './calendarDay.css'

type Props = {
  /** The date associated with the calendar cell */
  date: Date,
  /** Controls the visuals state of a `CalendarDay` */
  state: CalendarDayState,
}

export const CalendarDay = ({date, state}: Props) => (
  <div
    className="calendar-day-container"
  >
    <span 
      className={state === CalendarDayState.NotRequired ? `not-required` : ``}
    >
    {formatDate(date.getDate())}
    </span>
    {
      state === CalendarDayState.Confirmed &&
      <ConfirmedSymbol />
    }
    {
      state === CalendarDayState.Unconfirmed &&
      <UnconfirmedSymbol />
    }
    {
      state === CalendarDayState.NotRequired &&
      <>
        <NotRequiredSymbol />
        <div className="calendar-day-overlay">
        </div>
      </>
    }
  </div>
)