export const mapTimeZoneToSelect = (day:string) => {

    switch (day) {
        case 'cst':
            return {value: "cst", label:"Centeral Standard Time", grouplabel:false}
        case 'est' :
            return {value: "est", label:"Eastern Standard Time", grouplabel:false}
        case 'pst' :
            return {value: "pst", label:"Pacific Standard Time", grouplabel:false}
        case 'ast':
            return {value: "ast", label:"Alaskan Standard Time", grouplabel:false}
        case 'hst':
            return {value: "hst", label:"Hawaii-Aleutian Standard Time", grouplabel:false}
        default:
            return {value: "cst", label:"Centeral Standard Time", grouplabel:false}
    }
}