import React, { useContext, useEffect } from 'react';
import {Navbar, Nav, NavDropdown, Container} from 'react-bootstrap'
import { useState } from 'react';
import { user1 , user2} from '../../api/mock_data';
import { useResize } from '../../hooks/useResize';

import "../../App.css"
import "./navigation.css"
import DropsContext from '../../models/Context';

const Navigation = () => {
  const context = useContext(DropsContext)
  const {dropsState:{currentUser}} = context
  //these could all be in state context.
  const [pathname, setPathname] = useState<string>('');
  const [userAdmin, setUserAdmin] = useState(Boolean);
  const [userProfile, setUserProfile] = useState<string>('');

  useEffect(() => {
    let path = window.location.pathname;

    if(path.includes('time')){
      setPathname('time')
    } else if (path.includes('admin')){
      setPathname('admin')
    } else if (path.includes('reports')){
      setPathname('reports')
    } 
    setUserAdmin(currentUser.Admin)
    let profileImg = currentUser.AvatarUrl
    profileImg = typeof profileImg === 'object' ? '/profile-temp2.png' :  currentUser.AvatarUrl
    
    setUserProfile(profileImg)
  })

  const resize = useResize();

  return (
      <Navbar collapseOnSelect expand="lg">
        <Container>
          <Navbar.Brand href="/time">
            <img
              alt=""
              src="/fill-1.png"
              className="d-inline-block align-text-bottom"
            />{' '}
            <span className='logo-link'>Drops</span>
          </Navbar.Brand>
          <Navbar
            className={resize['mobile'] ? "d-none" : "ms-auto"}
          >
            <Nav className="nav-text">
            <Nav.Link href="/time" id={pathname === 'time' ? "active" : "inactive"}>TIME</Nav.Link>
            <Nav.Link href="/reports" id={pathname === 'reports' ? "active" : "inactive"}>REPORTS</Nav.Link>
            <NavDropdown
              title={
              <span id={pathname === 'admin' ? "active" : "inactive"}>ADMIN</span>}
              id="nav-dropdown"
              className={userAdmin ? "" : "d-none"}
            >
              <NavDropdown.Item href="/admin/projects" className='links'>PROJECTS</NavDropdown.Item>
              <NavDropdown.Item href="/admin/employees" className='links'>EMPLOYEES</NavDropdown.Item>
              <NavDropdown.Item href="/admin/departments" className='links'>DEPARTMENTS</NavDropdown.Item>
            </NavDropdown>
          </Nav>
          </Navbar>
          <Nav.Link href="/settings"><img src={userProfile != '' ? userProfile : '/profile-temp1.png'} alt="profile picture" className='profile-picture' referrerPolicy='no-referrer' /></Nav.Link>
        </Container>
      </Navbar>
  );
}

export default Navigation;
