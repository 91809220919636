import React, {ChangeEvent, KeyboardEvent, useContext} from 'react'
import {restrictValue} from './utility'
import './timeEntryField.css'
import DropsContext from '../../models/Context';
import { setSelectedTimeEntry } from '../../context/DropsDispatch/TimeEntryDispatch';


type Props = {
  /** The time value for the field. Pass null to see the placeholder value. */
  time: number | null,
  /** The on change callback for when the time changes by the user. */
  onChange: (time: number | null) => void,
  isDisabled: boolean;
  className?: string;
}

export const TimeEntryField = ({time, onChange, isDisabled, className}: Props) => {
  const [backspace, setBackspace] = React.useState(false)
  const context = useContext(DropsContext);
  const {
    dropsState: {
      editEntryModal,
      selectedTimeEntry
    }
  } = context;

  const onKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    const floatValue = parseFloat(e.key)

    // Block NaN but not the decimal place or backspace
    if (isNaN(floatValue) && e.key !== '.' && e.key !== 'Backspace') {
      e.preventDefault()  
    }

    if (e.key === 'Backspace') {
      setBackspace(true)
    } else {
      setBackspace(false)
    }
  }

  const _onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const floatValue = parseFloat(e.target.value)
    if (isNaN(floatValue)) {
      onChange(null) 
    } else {
      onChange(restrictValue(floatValue, backspace))
    }

    setBackspace(false)
  }

  return (
    <div>
      <input
        className={isDisabled ? `time-entry-field ${className} time-entry-field-disabled` : `time-entry-field ${className}`}
        type="number"
        placeholder="0.00"
        // Pass empty string when null because null makes the compiler complain.
        value={time===null ? '' : time?.toPrecision(3)}
        min="0"
        max="24"
        step="0.25"
        pattern="[0-9]*"
        inputMode="decimal"
        onKeyDown={onKeyDown}
        onChange={_onChange}
      >
      </input>
    </div>
  )
}