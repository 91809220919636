import React, { useContext, useState } from "react";
import { GoogleLogin } from "react-google-login";
import {
  GoogleLoginResponse,
  GoogleLoginResponseOffline,
} from "react-google-login";
import { isGoogleLoginResponseOffline } from "./loginbutton_helper";

import DropsContext from "../../models/Context";
import { Login } from "../../api/Auth";

let clientVar: string | undefined = process.env.REACT_APP_GOOGLE_CLIENT_ID;
let clientID = typeof clientVar === "string" ? clientVar : "";

interface Props {
  isLoggedIn(): void;
}

const LoginButton = ({ isLoggedIn }: Props) => {
  const [errorMsg, setErrorMsg] = useState("");
  const context = useContext(DropsContext);

  const onSuccess = async (
    props: GoogleLoginResponse | GoogleLoginResponseOffline
  ) => {
    if (isGoogleLoginResponseOffline(props))
      return setErrorMsg("Unexpected Google response");
    /* deconstructe data if response is valid */
    const data = props.profileObj;
    const { imageUrl, email, name } = data;

    /* store data in local storage */
    localStorage.setItem("email", email);
    localStorage.setItem("name", name);
    localStorage.setItem("profileImg", imageUrl);

    /* send google oauth information to backend */
    localStorage.setItem("token", props.tokenId);

    await Login(props.tokenId, context);
    isLoggedIn();
  };

  const onFailure = () => {
    setErrorMsg("Oops, something went wrong, try again");
  };

  return (
    <div className="App">
      <div id="signInButton">
        <GoogleLogin
          clientId={clientID}
          buttonText="Login"
          onSuccess={onSuccess}
          onFailure={onFailure}
          cookiePolicy={"single_host_origin"}
          isSignedIn={true}
          prompt={"consent"}
        />
      </div>
      <span className="error-msg">{errorMsg}</span>
    </div>
  );
};

export default LoginButton;
