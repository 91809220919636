import { IDropsContext } from "../../../../models/Context";
import { Project, Projects } from "../../../../models/Projects";
import { Option } from "../../../../components/DropDown/dropdown.interfaces";
import { Clients, defaultClient } from "../../../../models/Client";

export const findProjectById = (
  projectId: string | number,
  context: IDropsContext
): Project | null => {
  for(let client of context.dropsState.clients ){
    const val = client.Projects?.find((project) => {
      return project.Id.toString() == projectId.toString();
    });
    if (!!val) {
      return val;
    }
  };
  return null;
};

export const getProjects = (context: IDropsContext): Projects => {
  const projects: Projects = [];
  context.dropsState.clients.map((client) => {
    client.Projects?.map((project) => {
      projects.push(project);
    });
  });
  return projects;
};
//maybe one day
export const sortProjects = () => {};

export const findClientById = (clientId: number | string, context: IDropsContext) => {
  const val = context.dropsState.clients.find(
    (client) => client.Id == clientId
  );
  if (!!val) {
    return val;
  }
  return null;
};

export const getClientOptions = (context: IDropsContext): Option[] => {
  return context.dropsState.clients.map((client) => {
    return {
      label: client.Name,
      value: client.Id.toString(),
      grouplabel: false,
    };
  });
};

export const getSelectedClientOption = (
  context: IDropsContext
): Option | null => {
  const { selectedClient } = context.dropsState;
  if (selectedClient === defaultClient) {
    return null;
  }
  return {
    label: selectedClient.Name,
    value: selectedClient.Id.toString(),
    grouplabel: false,
  };
};

export const getClientByName = (clientName: string, clients: Clients) => {
  return clients.find((client) => client.Name == clientName);
};
