const departments = [
    {
        "id": 38,
        "name": "Admin",
        "managerId": 193
    },
    {
        "id": 39,
        "name": "QA/Testing",
        "targetBillableHours": 6.5,
        "targetHours": 7.5,
        "billable": true,
        "managerId": 193
    },
    {
        "id": 40,
        "name": "Server Dudes",
        "targetBillableHours": 6,
        "targetHours": 7.5,
        "billable": true,
        "managerId": 193
    },
    {
        "id": 41,
        "name": "iOS",
        "targetBillableHours": 6.5,
        "targetHours": 7.5,
        "billable": true,
        "managerId": 203
    },
    {
        "id": 42,
        "name": "Project Managers",
        "targetBillableHours": 4.5,
        "targetHours": 7,
        "billable": true,
        "managerId": 205
    },
    {
        "id": 43,
        "name": "Android",
        "targetBillableHours": 6.5,
        "targetHours": 7.5,
        "billable": true,
        "managerId": 207
    },
    {
        "id": 44,
        "name": "Design",
        "targetBillableHours": 6.5,
        "targetHours": 7.5,
        "billable": true,
        "managerId": 200
    },
    {
        "id": 45,
        "name": "Connected Devices"
    },
    {
        "id": 46,
        "name": "Full Stack"
    }
]

export {departments};