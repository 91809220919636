/* import hooks */
import { useState, useEffect, useContext } from "react";
import { useResize } from "../../../hooks/useResize";

/* import types & interfaces */
import {
  valueMulti,
  valueSingle,
} from "../../../components/DatePicker/datepicker";
import { Option } from "../../../components/DropDown/dropdown.interfaces";

/* import components */
import DropDownProjects from "../../../components/DropDown/Projects/projects";
import DropDownEmployees from "../../../components/DropDown/EmployeeByDep/employeeByDep";
import TimeFrame from "../../../components/TimeFrame/timeframe";
import AddButton from "../../../components/addButton/addbutton";

/* import stylesheets */
import "./reports.css";
import "../../../App.css";
import {
  findClientById,
  findProjectById,
} from "../../Admin/Projects/utils/project";
import DropsContext from "../../../models/Context";
import {
  clearSelectedTimeEntry,
  clearTimeEntries,
  setSelectedTimeEntry,
} from "../../../context/DropsDispatch/TimeEntryDispatch";
import { getExpandedClients } from "../../../api/Clients";
import { setSelectedProject } from "../../../context/DropsDispatch/SelectedProjectDispatch";
import {
  getTimeEntriesForUser,
  getTimeEntriesReport,
} from "../../../api/TimeEntry";
import { ReportFilters } from "../../../models/Reports";
import ReportTimeEntry from "../../../components/ReportTimeEntries/reportTimeEntry";

const tempSummary = {
  heading: "heading 1",
  subHeading: "subheading",
  timeEntries: [
    { hours: "8", description: "description" },
    { hours: "8", description: "description" },
    { hours: "8", description: "description" },
  ],
};

const Reports = () => {
  const [projectOrClient, setProjectOrClient] = useState<Option | null>({
    value: "all",
    label: "All Projects",
    grouplabel: true,
    archive: false,
  });
  const [employeeOrDepartment, setEmployeeOrDepartment] =
    useState<Option | null>({
      value: "all",
      label: "All Employees",
      grouplabel: true,
      archive: false,
    });
  const context = useContext(DropsContext);
  const {
    dropsState: { clients },
  } = context;
  const [summary, setSummary] = useState(tempSummary);
  const [timeFrameLabel, setTimeFrameLabel] = useState<string>("All Time");
  const [timeFrame, setTimeFrame] = useState<valueMulti | valueSingle>();

  const resize = useResize();

  useEffect(
    () => () => {
      clearTimeEntries(context);
    },
    []
  );

  useEffect(() => {
    clients.length < 1 && getExpandedClients(context);
    clearSelectedTimeEntry(context);
  }, []);

  const onChangeTimeFrame = (
    value: valueMulti | valueSingle,
    label: string
  ) => {
    setTimeFrame(value);
    setTimeFrameLabel(label);
  };

  const onChangeProjectOrClient = (option: Option | null) => {
    setProjectOrClient(option);
  };

  const onChangeEmployeeOrDepartment = (option: Option | null) => {
    setEmployeeOrDepartment(option);
  };

  const onProjectChange = (option: Option) => {
    const foundProject = findProjectById(option.value, context);
    if (!foundProject) {
      const foundClient = findClientById(option.value, context);
      foundClient &&
        setProjectOrClient({
          value: foundClient.Id.toString(),
          label: foundClient.Name,
          grouplabel: true,
          archive: false,
        });
      //setSelectedProject(foundProject, defaultClient, context);
    } else {
      foundProject &&
        setProjectOrClient({
          value: foundProject.Id.toString(),
          label: foundProject.Name,
          grouplabel: true,
          archive: false,
        });
    }
  };

  const runReport = () => {
    let start: Date | undefined;
    let end: Date | undefined;
    let err: boolean = false;
    const isArray = Array.isArray(timeFrame);
    if (timeFrame && !isArray) {
      start = timeFrame;
    } else if (isArray && timeFrame[0] && !timeFrame[1]) {
      start = timeFrame[0];
    } else if (isArray && timeFrame[0] && timeFrame[1]) {
      start = timeFrame[0];
      end = timeFrame[1];
    } else {
      err = true;
      alert("Could not complete request");
    }
    if (!err && start) {
      getTimeEntriesReport(start, end || start, context, {
        projectId:
          projectOrClient?.value === "all" ? undefined : projectOrClient?.value,
        userId:
          employeeOrDepartment?.value === "all"
            ? undefined
            : employeeOrDepartment?.value,
      });
    }
  };

  return (
    <div className="reports App flex-col">
      <h1 className="page-header">Reports</h1>
      <DropDownProjects
        value={parseInt(projectOrClient?.value || "")}
        onChange={onProjectChange}
        grouped={false}
        showLabel={true}
        isDisabled={false}
        className={resize["tablet"] ? "width-610" : "width-928"}
      />
      <DropDownEmployees
        value={employeeOrDepartment}
        onChange={onChangeEmployeeOrDepartment}
        grouped={false}
        showLabel={true}
        isDisabled={false}
        className={resize["tablet"] ? "width-610" : "width-928"}
      />

      <div className={resize["tablet"] ? "width-610" : "width-928"}>
        <TimeFrame onChange={onChangeTimeFrame} />
        <div className="add-btn-container d-flex justify-content-end align-items-end ">
          <AddButton
            disabled={false}
            labeltext={"run report"}
            onClick={runReport}
          />
        </div>
      </div>

      <div className="report-body">
        <div>
          <p className="section-header">{`${projectOrClient?.label} / ${employeeOrDepartment?.label} / ${timeFrameLabel}`}</p>
        </div>
        <ReportTimeEntry />
      </div>
    </div>
  );
};

export default Reports;
