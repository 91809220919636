import { useState } from 'react';

/* components */
import DropDownEmployeeType from '../DropDown/EmployeeType/employeeType';
import DropDownDepartment from '../DropDown/Department/department';
import DropDownTimeZone from '../DropDown/TimeZone/dropdownTimeZone';
import TextField from '../TextField/textfield';
import CheckBox from '../CheckBox/checkbox';
import { TimeEntryField } from '../TimeEntryField/timeEntryField';
import DatePickerContainer from '../DatePicker/SingleDatePicker/datepicker';
import ConfirmationDays from '../ConfirmationDays/confirmationDays';
import { Modal, Button } from 'react-bootstrap';
import ArchiveButton from '../ArchiveButton/addButton/archivebutton';

/* types and interfaces */
import { Option } from '../DropDown/dropdown.interfaces';
import { ConfirmedDays, EmployeeDataProps } from './AddEmployees.interfaces';

/* stylesheets */
import './addEmployee.css'

interface Props {
  data: EmployeeDataProps
  handleClose:()=>void;
  edit:boolean
  archived:boolean
  handleHide:(x:boolean)=>void;
  id?:string
  className?:string
}

const AddEmployee = ({data, handleClose, handleHide, edit, archived}:Props) => {
  const [department, setDepartment] = useState<Option|null>(data.department ?? null);
  const [timezone, setTimeZone] = useState<Option|null>(data.timezone ?? null);
  const [employeeType, setEmployeeType] = useState<Option|null>(data.employeeType ?? null );
  const [employeeName, setEmployeeName] = useState<string|null>(data.employeeName ?? null);
  const [email, setEmail] = useState<string|null>(data.email ?? null);
  const [targetHours, setTargetHours] = useState<number | null>(data.targetHours ?? null);
  const [targetBillableHours, setTargetBillableHours] = useState<number | null>(data.targetBillableHours ?? null);
  const [vacationTime, setVacationTime] = useState<number | null>(data.vacationTime ?? null);
  const [startDate, setStartDate] = useState<Date | null>(data.startDate ?? new Date());
  const [isAdmin, setIsAdmin] = useState<boolean>(data.isAdmin ?? false);
  const [isDepartmentHead, setIsDepartmentHead] = useState<boolean>(data.isDepartmentHead ?? false);
  const [canRunReport, setCanRunReport] = useState<boolean>(data.canRunReport ?? false);
  const [isArchived, setIsArchived] = useState<boolean>(archived);
  const [confirmationDays, setConfirmationDays] = useState<ConfirmedDays>(data.confirmationDays ?? []);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  /* get employee's name from Textfield component */
  const getEmployeeName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmployeeName(event.target.value)
  };

  /* get employee's email from Textfield component */
  const getEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value)
  };

  /* get employee's department from DropDown component */
  const getDepartment = (option: Option | null) => {
    setDepartment(option);
  };

  /* get employee's time zone from DropDown component */
  const getTimeZone = (option: Option | null) => {
    setTimeZone(option);
  };

  /* get employee's employee type from DropDown component */
  const getEmployeeType = (option: Option | null) => {
    setEmployeeType(option);
  };

  /* get employee's target hours from TimeEntryField component */
  const getTargetHours = (time: number | null) => {
    setTargetHours(time)
  }

  /* get employee's target billable hours from TimeEntryField component */
  const getTargetBillableHours = (time: number | null) => {
    setTargetBillableHours(time)
  }

  /* get employee's vacation time from TimeEntryField component */
  const getVactionTime = (time: number | null) => {
    setVacationTime(time)
  }

  /* get employee's start date from DatePicker component */
  const getStartDate = (date: Date | null) => {
    setStartDate(date)
  }

  /* get boolean value from CheckBox */
  const getIsDropsAdmin = (value:boolean) => {
    setIsAdmin(value)
  }

  /* get boolean value from CheckBox */
  const getIsDepartmentHead = (value:boolean) => {
    setIsDepartmentHead(value)
  }

  /* get boolean value from CheckBox */
  const getCanRunReports = (value:boolean) => {
    setCanRunReport(value)
  }

  /* get confirmation days from ConfirmationDays */
  const getConfirmationDays = (days:ConfirmedDays) => {
    setConfirmationDays(days)
  }


  // on submit send request to backend
  const onSubmit = () => {
    /* send all of the data to the back end */
    console.log(
      `department: ${department}`,
      `time zone: ${timezone}`,
      `employee type: ${employeeType}`,
      `employee name: ${employeeName}`,
      `email: ${email}`,
      `targetHours: ${targetHours}`,
      `targetBillableHours: ${targetBillableHours}`,
      `vacationTime: ${vacationTime}`,
      `startDate: ${startDate}`,
      `isAdmin: ${isAdmin}`,
      `isDepartmentHead: ${isDepartmentHead}`,
      `canRunReport: ${canRunReport}`,
      `confirmationDays: ${confirmationDays}`,
    )

    onClose(false, true)
  }

  const onArchive = () => {
    /* on archive, archive the individual employee */
    setIsArchived(true)
    setIsOpen(false)
    handleHide(false)
  }

  const onUnArchive = () => {
    /* reset archive */
    setIsArchived(!isArchived)
  }

  const onOpen = () => { /* on open, set isOpen to true (opens up confirmation pop up)*/
    setIsOpen(true)      /* and call handleHide to hide the employee popup  */
    handleHide(true)
  }

  const onClose = (hidden:boolean, closeConfirmPopUp:boolean) => { /* on close, handles whether the employee pop up is hidden */
    if ( hidden != null ) handleHide(hidden)                       /* handles whether the confirmation popup is closed or if  */
    closeConfirmPopUp != null && !closeConfirmPopUp ? handleClose() : setIsOpen(false) /* both the confirmation popup and employee popup is closed */
  }

  return (
    <div className={'add-employee-container d-flex flex-column'}>
        <div className='popup-header'>{isArchived || edit ? 'Edit Employee ': 'Add Employee'}</div>
        <fieldset disabled={isArchived ? true : false}>
            <div className='form-container'>
              <div className='add-employee-input-container'>
                <TextField
                  fieldText={employeeName??''}
                  onChange={getEmployeeName}
                  label={'Employee'}
                  isDisabled={isArchived ? true : false}
                  isValid
                  className={'add-employee-input-lg-width'}
                  placeholder={'Enter Employee Name'}
                />
              </div>
              <div className='d-flex align-items-end'>
                <TextField
                    fieldText={email??''}
                    onChange={getEmail}
                    label={'Email Address'}
                    isDisabled={isArchived ? true : false}
                    isValid
                    className={'add-employee-input-sm-width'}
                    placeholder={'Enter Work Email Address'}
                  />
                  <div className={isArchived ? 'email-ending text-disabled' : 'email-ending'}>
                    @mercury.io
                  </div>
              </div>
              {/* column A */}
              <div className='row-container d-flex flex-row align-items-start justify-content-start'>
                <div className='col-A d-flex flex-column align-items-start'>
                  <div className='add-employee-input-container'>
                    <DropDownDepartment
                      value={department}
                      onChange={getDepartment}
                      showLabel={true}
                      className={'add-employee-input-width'}
                      isDisabled={isArchived ? true : false}
                    />
                  </div>
                  <div className='add-employee-input-container d-flex align-self-start '>
                    <DropDownTimeZone
                      value={timezone}
                      onChange={getTimeZone}
                      showLabel={true}
                      className={'add-employee-input-width'}
                      isDisabled={isArchived ? true : false}
                    />
                  </div>
                  <div className='add-employee-input-container d-flex align-self-start'>
                      <div className='d-flex flex-column'>
                        <p className={isArchived ? 'component-header text-disabled' : 'component-header'}> Target Hours</p>
                        <TimeEntryField
                          time={targetHours}
                          onChange={getTargetHours}
                          isDisabled={isArchived ? true : false}
                          className={isArchived ? 'add-employee-input-width disabled-field' : 'add-employee-input-width'}
                        />
                      </div>
                  </div>
        
                  <div className='add-employee-input-container d-flex align-self-start'>
                    <div className='d-flex flex-column'>
                      <p className={isArchived ? 'component-header text-disabled' : 'component-header'}>Start Date</p>
                      <DatePickerContainer
                        disabled={isArchived ? true : false}
                        value={startDate}
                        onChange={getStartDate}
                        className={'add-employee-input-width'}
                      />
                    </div>
                  </div>
        
                  <div className='add-employee-input-container d-flex align-self-start'>
                    <div className='add-employee-checkbox-container permissions d-flex flex-column align-items-start'>
                      <p className={isArchived ? 'component-header text-disabled' : 'component-header'}>permissions</p>
                      <div className='margin-left-sm'>
                        <CheckBox
                          isDisabled={isArchived ? true : false}
                          labeltext={'Drops Admin Access'}
                          onClick={getIsDropsAdmin}
                          isClicked={isAdmin}
                        />
                      </div>
                      <div className='margin-left-sm'>
                        <CheckBox
                          isDisabled={isArchived ? true : false}
                          labeltext={'Department Head'}
                          onClick={getIsDepartmentHead}
                          isClicked={isDepartmentHead}
                        />
                      </div>
                      <div className='margin-left-sm'>
                        <CheckBox
                          isDisabled={isArchived ? true : false}
                          labeltext={'Run Reports'}
                          onClick={getCanRunReports}
                          isClicked={canRunReport}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                {/* column B */}
                <div className='col-B d-flex flex-column align-items-start'>
                  <div className='add-employee-input-container'>
                    <DropDownEmployeeType
                      value={employeeType}
                      onChange={getEmployeeType}
                      showLabel={true}
                      isDisabled={isArchived ? true : false}
                      className={'add-employee-input-width'}
                    />
                  </div>
                  <div className='add-employee-input-container'>
                    <div className=''>
                      <ConfirmationDays
                        onChange={getConfirmationDays}
                        value={confirmationDays}
                        isDisabled={isArchived ? true : false}
                      />
                    </div>
                  </div>
        
                  <div className='add-employee-input-container'>
                      <p className={isArchived ? 'component-header text-disabled' : 'component-header'}>Target Billable Hours</p>
                      <TimeEntryField
                        time={targetBillableHours}
                        onChange={getTargetBillableHours}
                        className={isArchived ? 'add-employee-input-width disabled-field' : 'add-employee-input-width'}
                        isDisabled={isArchived ? true : false}
                      />
                  </div>
                  <div className='add-employee-input-container'>
                    <p className={isArchived ? 'component-header text-disabled' : 'component-header'}>Starting Vaction Time</p>
                    <TimeEntryField
                      time={vacationTime}
                      onChange={getVactionTime}
                      className={isArchived ? 'add-employee-input-width disabled-field' : 'add-employee-input-width'}
                      isDisabled={isArchived ? true : false}
                    />
                  </div>
                  <div className='vacation-description-container d-flex justify-content-center'>
                    <p className={isArchived ? 'text-disabled vacation-description' : 'vacation-description'}>
                      Vacation time will increase on the employee's start date anniversary
                      at 3 years to 120 hours and at 5 years to 160 hours.
                      Up to 40 hours of unused vacation time will be
                      automatically rolled to the next year.
                    </p>
                  </div>
                </div>
              </div>
            </div>
        </fieldset>
        <div className={edit === false ? 'employee-modal-buttons d-flex justify-content-end' : 'employee-modal-buttons d-flex align-items-start justify-content-between'}>
          <div className='d-flex flex-row'>
            {/* for former employees */}
            <div className={edit === false ? 'd-none' : isArchived ? '' : 'd-none'}>
              <ArchiveButton disabled={false} labeltext={'Unarchive Employee'} onClick={ onUnArchive } />
            </div>
            {/* for current employees */}
            <div className={edit === false ? 'd-none' : isArchived ? 'd-none' : ''}>
              <ArchiveButton disabled={false} labeltext={ 'Archive Employee'} onClick={onOpen} />
            </div>
          </div>
          <div className='d-flex flex-row'>
            <div className='employee-pop-up-btn'>
              <Button variant="secondary" onClick={()=>onClose(true, false)} id='cancel-btn'>
                  Cancel
              </Button>
            </div>
            <div className='employee-pop-up-btn'>
              <Button variant="primary" onClick={isArchived ? ()=>{} : onSubmit} id={isArchived ? 'submit-btn-disabled' : 'submit-btn'}>
                  {isArchived || edit ? 'Save Changes' : 'Submit'}
              </Button>
            </div>
          </div>
        </div>


      <Modal show={isOpen} onHide={()=>onClose(false, true)} className='confirm-archive-popup'>
       <Modal.Body>
         <div className='confirm-archive-popup-container'>
           <div className='text'>
             <h1 className='popup-header'>Archive Employee</h1>
             <p>Are you sure you want to archive {employeeName}</p>
           </div>
           <div className='confirm-pop-up-btn-container d-flex flex-row justify-content-end'>
              <div className=''>
                 <Button
                   variant="secondary"
                   id='cancel-btn'
                   onClick={()=>onClose(false, true)}
                 >
                     Cancel
                 </Button>
               </div>
               <div className=''>
                 <ArchiveButton disabled={false} labeltext={ 'Archive Employee'} onClick={onArchive} />
               </div>
             </div>
         </div>
       </Modal.Body>
     </Modal>
    </div>
  );
}

export default AddEmployee;
