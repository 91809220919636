import { Department, Users } from "../../../components/DropDown/dropdown.interfaces"

interface DepartmentObj {
    id: number;
        name: string;
        managerID: number | undefined;
        employeeCount: number;
}


interface DepartmentCount {
    [key: string] : DepartmentObj
}

const getDepartments = (departments:Array<Department>, users:Array<Users>) => {
    let structureData: DepartmentCount = {}

    departments.map((department) => {
        structureData[department.id] = {
            id: department.id,
            name: department.name,
            managerID: department.managerId,
            employeeCount: 0,
        }
    })

    users.map((user) => {
        let departmentID = user.departmentId;

        structureData[departmentID].employeeCount ++
    })
 
    return Object.values(structureData)
}

export { getDepartments, DepartmentObj }