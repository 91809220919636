import { Projects } from "../../models/Projects";
import type { DataStructure, ProjectDataType, Department, Users } from "./dropdown.interfaces";


/* for combining two sets of data, ie projects and client or employee and deparment */
const dropdownProjects = (data: Projects) => {
    /*
        go through data, get the client name and project name, 
        check if the client name exists in the structuredData
        object. If it does append the project to the structureData[client name]
        array. If not create a new key value pair
    */
    const projects = data
    let structureData: DataStructure = {}

    /* 
        go through and structure the data:
        {
            'clientName1' : [{'projectName': projectName, 'archive':boolean}],
            'clientName2' : [{'projectName': projectName, 'archive':boolean}],
            'clientName3' : [{'projectName': projectName, 'archive':boolean}],
        }
    */
    // for (let project of projects) {
    //     let client = project['ClientName'];
    //     let projectName = project['ProjectName'];
    //     let projectID = project['Id'];
    //     let isArchive = !!project.deletedAt ? true : false;

    //     let keys = Object.keys(structureData);
    //     if (keys.includes(client) === false) {
    //         structureData[client] = [{
    //             'name': projectName, 
    //             'id': projectID.toString(),
    //             'archive':isArchive.toString(), 
    //             // 'clientName': client,
    //             // 'clientID': clientID.toString()
    //         }
    //     ]
    //     } else {
    //         let data = structureData[client]
    //         data.push({'name': projectName, 'id': projectID.toString(), 'archive':isArchive.toString()})
    //         structureData[client] = data
    //     }
    // }

    let newkeys = Object.keys(structureData).sort();
    let sortedData: DataStructure = {}

    /*
        alphabetize the data
    */
    for (let key of newkeys) {
        sortedData[key] = structureData[key]
    }

    /*
        return the sorted data
    */
    return sortedData
}

const dropdownEmployee = (departments:Array<Department>, users:Array<Users>) => {
    /*
        go through data, get the client name and project name, 
        check if the client name exists in the structuredData
        object. If it does append the project to the structureData[client name]
        array. If not create a new key value pair
    */
    let structureData: DataStructure = {}

    let departmentKeys = Object.keys(departments)
    for (let key in departmentKeys){
        structureData[departments[key]['id']] = []        
    }

    let userKeys = Object.keys(users)
    for (let key in userKeys){
        let name = `${users[key]['bcFirstName']}  ${users[key]['bcLastName']}`
        structureData[users[key]['departmentId']].push(
            {
                'name':name, 
                'archive':users[key]['bcDeleted'].toString(),
                'id': users[key]['bcUserID'].toString(),
            }
        )        
    }

    let structureDataKeys = Object.keys(structureData);
    for (let key in structureDataKeys){
        structureData[departments[key]['name']] = structureData[departments[key]['id']]
        delete structureData[departments[key]['id']]
    }
    
    return structureData
}

export { dropdownProjects, dropdownEmployee }
