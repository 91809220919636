import {
  GroupedOption,
  Option,
} from "../components/DropDown/dropdown.interfaces";
import { IDropsContext } from "../models/Context";
import { findProjectById } from "./Admin/Projects/utils/project";

export const getProjectOptions = (context: IDropsContext): GroupedOption[] => {
  let projectList: GroupedOption[] = [];
  context.dropsState.clients.map((client) => {
    client.Projects &&
      projectList.push({
        label: client.Name,
        options: client.Projects?.map((project) => {
          return {
            label: project.Name,
            value: project.Id.toString(),
            grouplabel: false,
          };
        }),
      });
  });
  return projectList;
};

export const getSelectedProjectOption = (
  context: IDropsContext
): Option | null => {
  const {
    selectedTimeEntry: { ProjectId },
  } = context.dropsState;
  const project = findProjectById(ProjectId, context);

  return project
    ? {
        label: project.Name,
        value: project.Id.toString(),
        grouplabel: false,
      }
    : null;
};

export const getUserOptions = (context: IDropsContext): Option[] => {
  let userList: Option[] = [];
  context.dropsState.employees?.map((user) => {
    userList.push({
      label: user.FirstName + " " + user.LastName,
      value: user.Id.toString(),
      grouplabel: false,
    });
  });
  return userList;
};
