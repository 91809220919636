import { IDropsContext } from "../../models/Context";
import { DropsActionType } from "../Actions";
import { clearSelectedTimeEntry } from "./TimeEntryDispatch";

export const showEditEntryModal =(context: IDropsContext)=>{
    context.dispatch({type:DropsActionType.OPEN_EDIT_ENTRY_MODAL})
}
export const hideEditEntryModal =(context: IDropsContext)=>{
    clearSelectedTimeEntry(context);
    context.dispatch({type:DropsActionType.CLOSE_EDIT_ENTRY_MODAL})
}