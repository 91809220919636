/* import component */
import DropDown from "../dropdown";

/* import interfaces & types  */
import { Option, DataStructure } from "../dropdown.interfaces";

/* import functions */
import { dropdownEmployee } from '../configdata';
import { createOptions, groupOptions } from '../dropdownhelper';

/* mock data */
import { users, departments } from "./data";
import { useContext, useEffect } from "react";
import DropsContext from "../../../models/Context";
import { getAllEmployees } from "../../../context/DropsDispatch/EmployeeDispatch";
import { getUserOptions } from "../../../pages/utils";

interface Props {
    onChange(option: Option | null): void;
    value: Option | null;
    grouped:boolean;
    isDisabled?: boolean;
    placeholder?:string;
    label?: string;
    className?: string;
    showLabel?:boolean;
} 


const DropDownEmployees = ({value, onChange, className, label, grouped, placeholder, isDisabled, showLabel}: Props) => {    
    /* configure label */  
    showLabel = showLabel === undefined ? true : showLabel
    label = label === undefined ? 'Employee or Department' : label

    /* reconfigure data to plug into the general dropdown component */
    let structureData: DataStructure = dropdownEmployee(departments, users)
 
    /* reconfigure original data for main dropdown component */
    let data = structureData
    let dataArr = Object.keys(data);


    let options;
    if (grouped) {
        options = groupOptions({ dataArr, data });
    } else {
        options = createOptions({ dataArr, data });
        options.unshift({
            "value": "all",
            "label": "All Employees",
            "grouplabel": false,
            "archive": false
        });
    }
    const context = useContext(DropsContext);
    const {employees} = context.dropsState;

    useEffect(()=>{
        if(!employees)
        {getAllEmployees(context)}
    },[])

    return (
        <div className="dropdown-employees">
            <p className={showLabel===false ? 'd-none' : isDisabled ? 'component-header drop-down-disabled-label' : 'component-header'}>{label}</p>
            <DropDown 
                data={getUserOptions(context)} 
                placeholder={placeholder ?? 'Employee or Department'} 
                onChange={onChange} 
                value={value}
                className={className}
                isDisabled={isDisabled ? isDisabled : false}
            />
        </div>
    );
}

export default DropDownEmployees;