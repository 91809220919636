import { Option, GroupedOption } from "../dropdown.interfaces"
/*
 data is already configured so that it can be directly
 plugged into dropdown.tsx
*/

const data:Option[] | GroupedOption[] = [
    {value: "full-time", label:"Full Time", grouplabel:false},
    {value: "part-time", label:"PartTime", grouplabel:false},
    {value: "seasonal", label:"Seasonal", grouplabel:false},
    {value: "temporary", label:"Hourly", grouplabel:false}
]

export default {data}