import React, { useState, useEffect } from 'react';

import { Modal, Button } from 'react-bootstrap';

import TextField from '../../../components/TextField/textfield';

import { departments, users } from './helper';
import { getDepartments, DepartmentObj } from './utils';

import './departments.css';
import '../../../App.css';

const Departments = () => {
  const [ isOpen, setIsOpen ] = useState<boolean>(false); // boolean indicating if popup is open
  const [ department, setDepartment ] = useState<string|null>(null); // string for new department name
  const [ error, setError ] = useState<boolean>(false); // boolean indicating if the department field has not been filled yet
  const [ colOneActive, setColOneActive ] = useState<boolean>(true); // boolean indicating if department column is active
  const [ colTwoActive, setColTwoActive ] = useState<boolean>(false); // boolean indicating if employee column is active
  const [ data, setData ]= useState<DepartmentObj[] | undefined >();

  useEffect(() => {
    const departmentObj = getDepartments(departments, users)
    setData(departmentObj)
  }, [])

  const handleShow = () => { // set popup as open
    setIsOpen(true)
  }

  const handleClose = () => { // set popup as closed
    setError(false)
    setIsOpen(false)
  }

  const onSubmit = () => { // submit new department, or let user know that the field has not been filled out
    if (department != '' &&  department != null) {
      handleClose()
      setDepartment(null)

      // and send
    } else {
      setError(true)
    }
  }

  const getDepartmentName = (event: React.ChangeEvent<HTMLInputElement>) => { // get department name
      setDepartment(event.target.value)
  }

  const activateColOne = () => { // activates col department
    setColOneActive(!colOneActive)
    setColTwoActive(!colTwoActive)
  }

  const activateColTwo = () => { // activates col employee
    setColTwoActive(!colTwoActive)
    setColOneActive(!colOneActive)
  }

  if (colOneActive && data != undefined) { // sort table via employee column
    data.sort((a:DepartmentObj, b:DepartmentObj) => {
        if(a.name.toLowerCase() < b.name.toLowerCase()) { return -1; }
        if(a.name.toLowerCase() > b.name.toLowerCase()) { return 1; }
        return 0;
    })
  } else if (colTwoActive && data != undefined) { // sort table via department column
      data.sort((a:DepartmentObj, b:DepartmentObj) => {
          if(a.employeeCount > b.employeeCount) { return -1; }
          if(a.employeeCount < b.employeeCount) { return 1; }
          return 0;
      })
  } 

  // organizes employees into table rows
  const alldepartments = data?.map((department:DepartmentObj) => {
    return (
      <tr
        key={department.id}
      >
          <td className='padding-left'>{department.name}</td>
          <td className='padding-left'>{department.employeeCount}</td>
          <td></td>
      </tr>
    )
  })

  return (
    <div className="departments App">
      <h1 className='page-header'>Departments</h1>
      <div className='button-container d-flex flex-align-start'>
        <Button
          onClick={handleShow}
          id={"adddepartment-btn"}
        >
          + ADD DEPARTMENT
        </Button>
      </div>

      <div>
      <table id='department-table'>
          <tbody>
            <tr>
                <th
                    onClick={activateColOne}
                    className={"table-headers padding-left"}
                >
                    Department
                    <img src={colTwoActive === true ? "/sort-light-down-active.svg" : "/sort-light-down-inactive.svg"} alt="" />
                </th>
                <th
                    onClick={activateColTwo}
                    className={"table-headers padding-left"}
                >
                    Employee
                    <img src={colOneActive === true ? "/sort-light-down-active.svg" : "/sort-light-down-inactive.svg"} alt="" />
                </th>
                <th>
                </th>
            </tr>
            {alldepartments}
          </tbody>
        </table>
      </div>


      <Modal show={isOpen} onHide={handleClose} className='departments-container'>
        <Modal.Body>
            <div className='department-popup d-flex flex-column justify-content-center'>
              <p className='popup-header'>Add Department</p>
              <div className={error ? 'd-flex flex-column align-items-center' : 'd-flex justify-content-center'}>
                <TextField
                    fieldText={ 'Enter Department Name'}
                    label={'Department'}
                    isDisabled={false}
                    isValid={true}
                    onChange={getDepartmentName}
                    className={'textfield-department'}
                />
                <div className={ error ? 'error-msg' : 'd-none'}>
                  Please fill out this field
                </div>
              </div>
            </div>
        </Modal.Body>
        <div className='d-flex justify-content-end'>
          <div className='department-modal-buttons department-modal-buttons'>
            <Button variant="secondary" onClick={handleClose} id='cancel-btn'>
                Cancel
            </Button>
          </div>
          <div className='department-modal-buttons department-modal-buttons'>
            <Button variant="primary" onClick={handleClose} id='submit-btn'>
                Submit
            </Button>
          </div>
        </div>
      </Modal>

    </div>
  );
}

export default Departments;
