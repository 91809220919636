import { useContext, useState } from "react";

import TextField from "../TextField/textfield";
import { TimeEntryField } from "../TimeEntryField/timeEntryField";
import DatePickerContainer from "../DatePicker/SingleDatePicker/datepicker";
import { Button } from "react-bootstrap";
import DeleteButton from "../DeleteButton/deleteButton";
import AddButton from "../addButton/addbutton";

import { Option } from "../DropDown/dropdownhelper";

import projectData from "../DropDown/Projects/data";

import { mapToSelectInput } from "./utils";

import "./timepagepopup.css";
import { TimePgTimeEntryData } from "../../pages/Time/timehelper";
import {
  clearSelectedTimeEntry,
  deleteUserTime,
  setSelectedTimeEntry,
  updateUserTime,
} from "../../context/DropsDispatch/TimeEntryDispatch";
import DropsContext, { IDropsContext } from "../../models/Context";
import DropDown from "../DropDown/dropdown";
import { getProjectOptions, getSelectedProjectOption } from "../../pages/utils";
import { useResize } from "../../hooks/useResize";
import { hideEditEntryModal } from "../../context/DropsDispatch/EditTimeEntryModalDispatch";
import { getTimeEntriesForUser } from "../../api/TimeEntry";

const TimePagePopUp = () => {
  const context = useContext(DropsContext);
  const {
    dropsState: { selectedTimeEntry },
  } = context;

  const [disableSave, setDisableSave] = useState<boolean>(false);
  const resize = useResize();

  const onTimeChange = (time: number | null) => {
    setSelectedTimeEntry(
      { ...selectedTimeEntry, Hours: time ? time : 0.0 },
      context
    );
    if (time === 0 || null) {
      setDisableSave(true);
    } else {
      setDisableSave(false);
    }
  };

  const onProjectChange = (option: Option) => {
    setSelectedTimeEntry(
      { ...selectedTimeEntry, ProjectId: parseInt(option.value) },
      context
    );
  };

  const onDescriptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedTimeEntry(
      { ...selectedTimeEntry, Description: e.target.value },
      context
    );
    if (e.target.value.length === 0) {
      setDisableSave(true);
    } else {
      setDisableSave(false);
    }
  };

  const getDate = (date: Date) => {
    setSelectedTimeEntry({ ...selectedTimeEntry, Date: date }, context);
  };

  const onDeleteEntry = () => {
    deleteUserTime(context);
    hideEditEntryModal(context);
  };

  const onSaveEntry = () => {
    updateUserTime(context);
    hideEditEntryModal(context);
  };

  return (
    <div className="time-pg-popup">
      <h1 className="page-header">Edit Time Entry</h1>

      <div
        className={
          "d-flex flex-row justify-content-between align-items-baseline margin"
        }
      >
        <div>
          <p className="component-header time-pg-margin-none">Date</p>
          <DatePickerContainer
            value={new Date(selectedTimeEntry.Date)}
            onChange={getDate}
            disabled={false}
          />
        </div>

        <div className="time-entry-container">
          <div>
            <p className="component-header">TIME</p>
            <TimeEntryField
              time={selectedTimeEntry.Hours}
              onChange={onTimeChange}
              isDisabled={false}
            />
          </div>
        </div>

        <div className="dropdown-projects">
          <p className="component-header">Project</p>
          <DropDown
            data={getProjectOptions(context)}
            placeholder="Project"
            value={getSelectedProjectOption(context)}
            onChange={onProjectChange}
            isDisabled={false}
            className={
              resize["mobileSM"]
                ? "width-300"
                : resize["mobile"]
                ? "width-450"
                : resize["tablet"]
                ? "width-550"
                : "width-450"
            }
          />
        </div>
      </div>
      <div className="text-field-container margin">
        <TextField
          fieldText={selectedTimeEntry.Description}
          isValid={true}
          label={"Description"}
          isDisabled={false}
          onChange={onDescriptionChange}
          errormsg={"error"}
        />
      </div>

      <div className="button-container margin d-flex flex-row justify-content-between">
        <DeleteButton
          disabled={false}
          labeltext={"Entry"}
          onClick={onDeleteEntry}
        />

        <div className="d-flex flex-row align-items-center">
          <Button onClick={() => hideEditEntryModal(context)} id={"cancel-btn"}>
            Cancel
          </Button>
          <div className={disableSave ? "d-none" : ""}>
            <AddButton
              disabled={false}
              labeltext={"Save Chanves"}
              onClick={onSaveEntry}
              className={disableSave ? "d-none" : ""}
            />
          </div>
          <div className={disableSave ? "" : "d-none"}>
            <AddButton
              disabled={true}
              labeltext={"Save Changes"}
              onClick={() => {}}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TimePagePopUp;
