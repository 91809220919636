import { DropsActionType } from "../context/Actions";
import { IDropsContext } from "../models/Context";
import { initialUser } from "../models/User";

let geturl: string | undefined = process.env.REACT_APP_BASE_URL;
let base_url = geturl !== undefined ? geturl : "";

export const Login = async (token: string, context: IDropsContext) => {
  const { dispatch } = context;
  try {
    await fetch(base_url + "/v1/auth/google", {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ token }),
    })
      .then((response) => {
        if (!response.ok) {
          localStorage.clear()
          if (response.status === 401) {
            dispatch({
              type: DropsActionType.USER_LOGOUT,
              payload: initialUser,
            });
          }
          throw new Error(response.statusText);
        } else {
          return response.json();
        }
      })
      .then((user) => {
        dispatch({
          type: DropsActionType.USER_LOGIN,
          payload: user,
        });
      });
    } catch {
    localStorage.clear()
    dispatch({
      type: DropsActionType.USER_LOGOUT,
      payload: initialUser,
    });
  }
};